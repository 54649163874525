import React, { useEffect, useMemo } from 'react';

import SideBar from '../../organisms/SideBar'
import TabList from '../../organisms/TabList'
import { useSelector, useDispatch } from 'react-redux';
import { AppStore, User, App, FilterType, Filter} from '../../../types'

import MainWithSidebar from '../../templates/MainWithSideBar'
import Jumbotron from '../../molecules/Jumbotron'
import AppCard from '../../organisms/AppCard'
import Account from '../../organisms/Account'
import Empty from '../../molecules/Empty'

import Loding from '../../atoms/Loading'
import FilterSelect from '../../organisms/FilterSelect'

import { indexApps, onHideFilter } from '../../../logics/actions/'
import { AppList } from '../../../types/'
import InfiniteScroll from 'react-infinite-scroll-component';

const Home: React.FC = () => {
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)
  const filter: Filter = useSelector((state: AppStore) => state.filter)
  const appList: AppList = useSelector((state: AppStore) => state.appList)
  const dispatch = useDispatch()

  const ScreenShotItems = (apps: App[]) => {
    return(
      apps.map((td) => <AppCard key={td.id} app={td} />)
    )
  };

  const render_jumbotron = (currentUser: User)=>{
    if (currentUser.isLoding){
      return null
    }
    if (currentUser.user != null){
      return null
    }else{
      return (
        <Jumbotron name={`yui.designは
        UIデザイナーのためのリファレンスサービスです。
        `}
        description="実務で参考になるデザインのみを集めました。日頃のUIデザインの参考にしてください。" />)
    }
  }

  useMemo(() => {
    indexApps(dispatch, filter.genre, null);
  },[filter, dispatch]);

  useEffect(() => {
    dispatch(onHideFilter());
  }, [dispatch])


  const load = () =>{
    indexApps(dispatch, filter.genre, appList.list[appList.list.length - 1]); 
  }

  return (
    <div>
      <MainWithSidebar sideBar={<SideBar />} >
        <Account />

        {render_jumbotron(currentUser)}

        <TabList name="iOS" tab="アプリ"/>
        <FilterSelect filters={[FilterType.GENRE]}/>
        {appList.isLoading ?  <Loding /> :appList.list.length === 0 ? <Empty /> : 
            <InfiniteScroll dataLength={appList.list.length} next={load} hasMore={!appList.isLast} loader={<Loding />}>
            {ScreenShotItems(appList.list)}
            </InfiniteScroll>
        } 

      </MainWithSidebar>
    </div>
  );
}

export default Home;

import React, { useState } from 'react'
import styled from "styled-components"
import media from 'styled-media-query'
import Media from 'react-media'

import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';


import Text from '../../atoms/Text'
import Modal from '../../atoms/Modal'
import Icon, { IconType } from '../../atoms/Icon'
import Toggle from '../../atoms/Toggle'
import AppInfo from '../../molecules/AppInfo'

import { onHideModal, addPagesScreenShot, addPartsScreenShot, deleteScreenShot,
  removePagesScreenShot, removePartsScreenShot, onShowDetailModal } from '../../../logics/actions'

import iphone from '../../assets/iphone.png'
import ArrowLeft from '../../assets/arrow_left.svg'
import ArrowRight from '../../assets/arrow_right.svg'

import { Modal as InModal , AppStore, App, User, partsFilter, pagesFilter, ModalType} from '../../../types/'

const Detail: React.FC = () => {
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)
  const modal: InModal | null = useSelector((state: AppStore) => state.modal)
  const dispatch = useDispatch()


  const [page, setPageState] = useState("Guided Tour")
  const [parts, setPartsState] = useState("Nav Bars")

  const handlePageChange = (event: any) => {
    setPageState(event.target.value);
  }

  const handlePartsChange = (event: any) => {
    setPartsState(event.target.value);
  }


  const Label = (title: string) =>(
    <RightPad><Toggle isActive={false}><TogglePadStyle>{title}</TogglePadStyle></Toggle></RightPad>
  )


  const PageLabel = (title: string) =>(
    <RightPad onClick={(e)=>removePages(e, title)}><Toggle isActive={false}><TogglePadStyle>{title}</TogglePadStyle></Toggle></RightPad>
  )

  const PartsLabel = (title: string) =>(
    <RightPad onClick={(e)=>removeParts(e, title)}><Toggle isActive={false}><TogglePadStyle>{title}</TogglePadStyle></Toggle></RightPad>
  )


  const PagesFilterItem = Object.keys(pagesFilter).map((item) =>
      <optgroup label={item} key={item}>
      {
        pagesFilter[item].map((mi) =>(
         <option key={mi} value={mi}>{mi}</option>)
        )
      }
      </optgroup>
  );


  const PartsFilterItem = Object.keys(partsFilter).map((item) =>
      <optgroup label={item} key={item}>
      {
        partsFilter[item].map((mi) =>(
         <option key={mi} value={mi}>{mi}</option>)
        )
      }
      </optgroup>
  );

  const getScreenShot = (app: App) => {
    return app.versions[0].screenShots.filter(s => s.id === modal.screenShot)[0]
  }


  const nextScreenShot = (dispatch: Dispatch) =>{
    if (modal.detail){
      var idx = modal.detail.versions[0].screenShots.findIndex(s => s.id === modal.screenShot)
      if(idx === modal.detail.versions[0].screenShots.length - 1){
        idx = 0
      }else{
        idx += 1
      }
      dispatch(onShowDetailModal(ModalType.DETAIL, modal.detail, modal.detail.versions[0].screenShots[idx].id))
    }
  }

  const backScreenShot = (dispatch: Dispatch) =>{
    if (modal.detail){
      var idx = modal.detail.versions[0].screenShots.findIndex(s => s.id === modal.screenShot)
      if(idx === 0){
        idx = modal.detail.versions[0].screenShots.length - 1
      }else{
        idx -= 1;
      }
      dispatch(onShowDetailModal(ModalType.DETAIL, modal.detail, modal.detail.versions[0].screenShots[idx].id))
    }
  }



  const addPages = (event: any) => {
    event.preventDefault();
    if (modal.detail){
      addPagesScreenShot(modal.detail, modal.detail.versions[0], getScreenShot(modal.detail), page, dispatch)
    }
  }
  const addParts = (event: any) => {
    event.preventDefault();
    if (modal.detail){
      addPartsScreenShot(modal.detail, modal.detail.versions[0], getScreenShot(modal.detail), parts, dispatch)
    }
  }



  const removePages = (event: any, page: string) => {
    event.preventDefault();
    if(currentUser.isAdmin){
      var conf = window.confirm("Are you sure you want to save this thing into the database?")

      if (conf) {
        if (modal.detail){
          removePagesScreenShot(modal.detail, modal.detail.versions[0], getScreenShot(modal.detail), page, dispatch)
        }
      }
    }
  }
  const removeParts = (event: any, parts: string) => {
    event.preventDefault();
    if(currentUser.isAdmin){
      var conf = window.confirm("Are you sure you want to save this thing into the database?")

      if (conf) {
        if (modal.detail){
          removePartsScreenShot(modal.detail, modal.detail.versions[0], getScreenShot(modal.detail), parts , dispatch)
        }
      }
    }
  }

  const deleteScreenShotHandle = (event: any) => {
    event.preventDefault();
    var conf = window.confirm("Are you sure you want to save this thing into the database?")
    if (conf) {
      if (modal.detail){
        deleteScreenShot(modal.detail, modal.detail.versions[0], getScreenShot(modal.detail), dispatch)
        dispatch(onHideModal());
      }
    }
  }


  return (

        <Modal>
          {modal.detail ?  (
          <DetailStyle>


            <Event onClick={()=> dispatch(onHideModal())}>

              <Media query="(max-width: 599px)" render={() =>(
                <Icon type={IconType.CloseWhite} size={22}/>
              )}/>

              <Media query="(min-width: 599px)" render={() =>(
                <Icon type={IconType.Close} size={19}/>
              )}/>


            </Event>

            <ScreenShotView>
              <ArrowLeftImg src={ArrowLeft} onClick={() => backScreenShot(dispatch)}/>
              <Image src={iphone} alt={getScreenShot(modal.detail).id} bg={getScreenShot(modal.detail).url}/>
              <ArrowRightImg src={ArrowRight} onClick={() => nextScreenShot(dispatch)}/>
            </ScreenShotView>
            <InfoView>
              <AppInfo name={modal.detail.name} description={modal.detail.description} image={modal.detail.versions[0].icon}/>

              <ScreenShotInfo>
                <Text color="#93989A" fontSize={12} lineHeight={16} isBold={true}>ジャンル</Text>
                <LabelList>
                  {Label(getScreenShot(modal.detail).genre)}
                </LabelList>
                <Text color="#93989A" fontSize={12} lineHeight={16} isBold={true}>ページ</Text>
                <LabelList>
                  {getScreenShot(modal.detail).pages !== "" ?
                  (PageLabel(getScreenShot(modal.detail).pages)): null }

                </LabelList>

                {currentUser.isAdmin ? (
                    <Edit>
                      <select name="pages" value={page} onChange={handlePageChange}>
                        {PagesFilterItem}
                      </select>
                      {modal.detail ?
                       <button onClick={(e)=> addPages(e)}>追加</button>
                      : null}
                    </Edit>
                  ): null}

                <Text color="#93989A" fontSize={12} lineHeight={16} isBold={true}>パーツ</Text>
                <LabelList>
                  {getScreenShot(modal.detail).parts ?
                  (getScreenShot(modal.detail).parts.map(i => PartsLabel(i))) : null}
                </LabelList>

                {currentUser.isAdmin ?(
                    <Edit>
                      <select name="parts" value={parts} onChange={handlePartsChange}>
                      {PartsFilterItem}
                      </select>
                    <button onClick={(e)=> addParts(e)}>追加</button>
                    </Edit>
                  ): null}
              </ScreenShotInfo>


              {currentUser.isAdmin ? <button onClick={(e) => deleteScreenShotHandle(e)}>スクリーンショットを削除</button> : null}
            </InfoView>
          </DetailStyle>
          ):null}
        </Modal>
  )

}

export default Detail;

const DetailStyle = styled.div`
  width: 856px;
  height: 655px;
  display: flex;
  ${media.lessThan("small")`
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
  `}
`

const Event = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 99
`;

const ScreenShotView = styled.div`
  background: #26353C;
  width: 464px;
  height: 100%;
  text-align: center;
  position: relative;
  ${media.lessThan("small")`
    width: 100vw;
    height: 665px;
  `}
`
const InfoView = styled.div`
  width: calc(100% - 464px);
  padding: 67px 48px 0px;
  ${media.lessThan("small")`
    width: 100vw;
    height: 100%;
    padding: 24px 16px 29px;
  `}
`

const ScreenShotInfo = styled.div`
  padding: 32px 0;
`

const Image = styled.img<{bg: string}>`
  width: 280px;
  margin: 42px auto;
  border-radius: 36px;
  background-size: 90.56%;
  background-image: url(${props => props.bg.replace(
    "https://firebasestorage.googleapis.com/v0/b/yui-design.appspot.com/o/"
  ,"https://storage.googleapis.com/yui-design.appspot.com/")});
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 72px 120px rgba(0, 0, 0, 0.13);
`
const TogglePadStyle = styled.div`
  padding 5px 16px;
`

const LabelList = styled.div`
  padding 8px 0 16px;
  display: flex;
  flex-flow: row wrap;
`
const RightPad = styled.div`
  padding-right: 12px;
  padding-bottom: 12px;
`

const ArrowLeftImg = styled.img`
  position: absolute;
  margin: auto;
  left: 0px;
  padding: 0 16px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  ${media.lessThan("small")`
    padding: 0 12px;
  `}
`
const ArrowRightImg = styled.img`
  position: absolute;
  margin: auto;
  right: 0px;
  padding: 0 16px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  ${media.lessThan("small")`
    padding: 0 12px;
  `}
`
const Edit = styled.div`
  margin: 0 0 16px;
`
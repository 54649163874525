import React,{ useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { App, Version, genreFilter } from '../../../types'
import { updateApp, updateVersion } from '../../../logics/actions'

import { storage } from '../../../utils/firebase'

const Admin: React.FC<{app: App}> = ({app}) => {

  const dispatch = useDispatch()


  const [state, setState] = useState({
    id: app.id,
    company: app.company,
    description: app.description,
    genre: app.genre,
    name: app.name,
    url: app.url,
    published: app.published,
    created_at: app.created_at
  });

  const [version, setVersionState] = useState({
    id: app.versions[0].id,
    name: app.versions[0].name,
    icon: app.versions[0].icon,
    icon_file: new File([""], "null"),
    published: app.versions[0].published
  });


  const handleChange = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleVersionChange = (event: any) => {
    setVersionState({ ...version, [event.target.name]: event.target.value });
  };


  const handleAppSubmit = (event: any) => {
    event.preventDefault();

    const update_app: App = {
      id: state.id,
      company: state.company,
      description: state.description,
      genre: state.genre,
      name: state.name,
      published: (state.published.toString() === 'true'),
      url: state.url,
      versions: [app.versions[0]],
      created_at: state.created_at,
      updated_at: Date.now()
    }

    updateApp(update_app, dispatch)
  };

  const handleVersionSubmit= (event: any) => {
    event.preventDefault();

    if(version.icon_file.name === "null"){
      const update_version: Version = {
        id: app.versions[0].id,
        name: version.name,
        icon: app.versions[0].icon,
        is_latest: true,
        published: version.published,
        screenShots: []
      }
      updateVersion(app, update_version, dispatch)
    }else{

      const metadata = {
        cacheControl: 'public,max-age=43200',
        contentType: 'image/jpeg'
      }

      storage.ref().child(app.id + "/" + app.versions[0].name + "/icon.jpg")
      .put(version.icon_file, metadata).then((snapshot: any) => {
          snapshot.ref.getDownloadURL().then(function(downloadURL: string) {
            const update_version: Version = {
              id: app.versions[0].id,
              name: app.versions[0].name,
              icon: downloadURL,
              is_latest: true,
              published: true,
              screenShots: []
            }
            updateVersion(app, update_version, dispatch)
          });
      }).catch((error: any) => {
          console.log(error)
      });
    }
  }

  const onIconChange = (event: any) => {
    setVersionState({ ...version, "icon_file" : event.target.files.item(0)});
  };


  return (
    <AdminStyle>
      <Title>管理者用コンソール</Title>

      <Console>
        <AppStyle>
        <Title>App</Title>
          <Label>Name:</Label><Input type="text" name="name" value={state.name} onChange={(e) => handleChange(e)} />
          <Label>Description:</Label><Input type="text" name="description" value={state.description} onChange={(e) => handleChange(e)} />
          <Label>Genre:</Label>
          <Select name="genre" value={state.genre} onChange={(e) => handleChange(e)} >
            {genreFilter.map((item) => <option key={item} value={item}>{item}</option>)}
          </Select>
          <Label>Company:</Label><Input type="text" name="company" value={state.company} onChange={(e) => handleChange(e)} />
          <Label>Url:</Label><Input type="text" name="url" value={state.url} onChange={(e) => handleChange(e)} />
          <Label>Published:</Label>
          <Select name="published" value={state.published.toString()} onChange={(e) => handleChange(e)} >
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
          <br />
          <Button onClick={handleAppSubmit}>更新</Button>
        </AppStyle>
        <VersionStyle>
          <Title>Version</Title>
          <Label>Name:</Label>
          <Input type="text" name="name" value={version.name} onChange={(e) => handleVersionChange(e)} />
          <Label>Icon:</Label>
          <Input type='file' name="icon" accept='image/*' onChange={e => onIconChange(e)} />
          <Label>Published:</Label>
          <Select name="published" value={version.published.toString()} onChange={(e) => handleVersionChange(e)} >
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
          <Button onClick={handleVersionSubmit}>更新</Button>

        </VersionStyle>
      </Console>

    </AdminStyle>
  );
}

export default Admin;

const AdminStyle = styled.div`
  font-family: 'Noto Sans';
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 1px 23px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 32px;
  padding: 32px;
  box-sizing: border-box;
  width: calc(100% - 64px);
`;

const AppStyle = styled.div`
  padding: 32px 16px;
  width: 50%;
`;

const VersionStyle = styled.div`
  padding: 32px 16px;
  width: 50%;
`
const Console = styled.div`
  display: fixed;
`



const Title = styled.div`
  font-weight: 700;
  margin: 20px 0;
`;

const Label = styled.div`
  font-family: 'Noto Sans';
`;

const Input = styled.input`
  font-family: 'Noto Sans';
	border: 1px solid #1b2538;
  border-radius: 4px;
	margin: 8px 0 16px;
  padding: 0.3em;
  font-size: 12px;
  width: 400px;
`;

const Select = styled.select`
  font-family: 'Noto Sans';
	border: 1px solid #1b2538;
  border-radius: 4px;
	margin: 8px 0 16px;
  padding: 0.3em;
  font-size: 12px;
  width: 410px;
`;

const Button = styled.button`
  background-color: #FFFFFF;
  color: #1B2B33;
  width: 410px;
  padding: 4px 90px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border: 1px solid #1B2B33;
  box-shadow: 0px 1px 23px rgba(0, 0, 0, 0.1);
`
import React from 'react';
import styled from 'styled-components'
import media from 'styled-media-query'
import Div100vh from 'react-div-100vh'
import { useSelector } from 'react-redux';
import { AppStore } from '../../../types'


import Head from '../../atoms/Head'

interface Props {
  sideBar: React.ReactNode,
  children: React.ReactNode,
}

const MainWithSideBar: React.FC<Props> = ({sideBar, children}) => {
  const is_sidebar: boolean = useSelector((state: AppStore) => state.sideBar.isShow)

  return (
    <div>
      <Container>
        <Div100vh >

        <SideBar isShow={is_sidebar}>
          {sideBar}
        </SideBar>

        </Div100vh>
        <Main>
          <Head />
          {children}
        </Main>
      </Container>
    </div>
  );
}

export default MainWithSideBar;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
`

const SideBar = styled.div<{isShow: boolean}>`
  width: 188px;
  height: 100%;
  background: #26353C;
  position: fixed;
  transition: 0.3s ease-in-out;
  left: ${props => props.isShow ?  "0": "-188px"};
  ${media.greaterThan("small")`
    left: 0;
  `}
`

const Main = styled.div`
  height: 100%;
  margin-left: 188px;
  margin-bottom: 200px;
  width: calc(100vw - 188px);
  background: rgba(256,256,256,1);
  overflow-x: hidden;
  overflow-y: scroll;
  ${media.lessThan("small")`
    width: 100vw;
    margin: 0;
`};
`
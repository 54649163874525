import * as actionTypes from '../../types/action.types';
import { Filter } from '../../types';

const initialState: Filter = {
  genre: null,
  pages: null,
  parts: []
};

export function filterReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SET_FILTER: {
      return {
        ...state,
        genre: action.genre,
        pages: action.pages,
        parts: action.parts,
      }
    }
    default:
      return state;
  }
}
import * as React from 'react';
import styled from 'styled-components';
interface Props {
  src: string,
  size: number,
}
interface State {}

class AppIcon extends React.Component<Props, State> {
  render() {
    return (
      <AppIconStyle src={this.props.src.replace(
        "https://firebasestorage.googleapis.com/v0/b/yui-design.appspot.com/o/"
      ,"https://storage.googleapis.com/yui-design.appspot.com/")
      } size={this.props.size}/>
    );
  }
};

export default AppIcon;


const AppIconStyle = styled.img<{size: number}>`
  color: #FFFFFF;
  border-radius: 22.5%;
  width: ${props => props.size+"px"};
  height: ${props => props.size+"px"};
  border: 1px solid #F5F5F5;
  box-sizing: border-box;
`
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Media from 'react-media';

import MenuHead from '../../molecules/MenuHead';
import MenuPage from '../../molecules/MenuPage';
import MenuLink from '../../molecules/MenuLink';
import BackButton from '../../molecules/BackButton';

import { onHideSidebar } from '../../../logics/actions'

const SideBar: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <SideBarStyle>

      <Media queries={{
          small: "(max-width: 480px)",
          large: "(min-width: 480px)"
        }}>
          {matches => (
            <div>
              {matches.small ? <BackPad onClick={()=> dispatch(onHideSidebar())}><BackButton /></BackPad> : null}
              {matches.large ? <MenuHead /> : null}
            </div>
          )}
        </Media>


      <MenuPage />
      <MenuLinkFooter>
        <MenuLink />
      </MenuLinkFooter>
    </SideBarStyle>
  );
}

export default SideBar;


const SideBarStyle = styled.div`
  width: 188px;
  `

const MenuLinkFooter = styled.div`
  bottom: 0;
  position: absolute;
  margin: 55px 0;
  z-index: 1;
`

const BackPad = styled.div`
  margin: 29px 13px 34px;
`
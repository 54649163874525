import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text'
import Back from '../../assets/back.svg'

const BackButton: React.FC = () => {
  return (
  <BackButtonStyle>
    <img src={Back} alt="back"/>
    <BackText>
      <Text lineHeight={19} fontSize={14} color="#FFFFFF" isBold={true}>閉じる</Text>
    </BackText>
  </BackButtonStyle>
  )
}

export default BackButton

const BackButtonStyle = styled.div`
  display: flex;
`

const BackText = styled.div`
  margin-left: 7px
`
import * as React from 'react';
import styled from 'styled-components';

interface State {}
interface Props {
  isActive: boolean,
}

class Radio extends React.Component<Props, State> {

  render() {
    return (
      <RadioStyle>
        { this.props.isActive ?
          (<RadioIconActive></RadioIconActive>):(<RadioIconInActive />)
        }
      </RadioStyle>
    );
  }
};

export default Radio;

const RadioStyle = styled.div`
`
const RadioIconInActive = styled.div`
  background: #93989A;
  border: 1px solid #93989A;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
`

const RadioIconActive = styled.div`
  background: #1B2B33;
  border: 4.444px solid #FFFFFF;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
`
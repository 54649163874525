import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom'


import AppIcon from '../../atoms/AppIcon'
import Text from '../../atoms/Text'
import ToggleSelecter from '../../molecules/ToggleSelecter'

interface Props {
  name: string,
  description: string
  icon: string,
  genre: string,
  company: string,
  url: string,
}



const AppInfoLarge: React.FC<Props> = ({name, description, icon, genre ,company, url}) => {

  const history = useHistory();

  const onGenreClick =  (genre: string) =>{
    history.push({
      pathname: '/',
      search: 'genre=' + genre,
    });
  }


  return (
    <AppInfoLargeStyle>
      <AppIcon src={icon} size={136}/>
      <InfoStyle>
        <Link href={url}>
          <Text color="#282F2C" fontSize={28} lineHeight={38} isBold={true}>{name}</Text>
        </Link>
        <DescriptionStyle>
          <Text color="#93989A" fontSize={12} lineHeight={16} isBold={false}>{description}</Text>
          <Text color="#93989A" fontSize={12} lineHeight={16} isBold={false}>{company}</Text>
        </DescriptionStyle>
        <OnGenreClick onClick={() => onGenreClick(genre)}>
          <ToggleSelecter name={genre} label="ジャンル" />
        </OnGenreClick>
      </InfoStyle>
  </AppInfoLargeStyle>
  );
}

export default AppInfoLarge;

const AppInfoLargeStyle = styled.div`
  display: flex;
  padding: 8px 0;
`
const InfoStyle = styled.div`
  padding: 2px 0px 4px 16px;
`
const DescriptionStyle = styled.div`
  padding: 8px 0 12px;
`

const Link = styled.a`
  text-decoration: none;
`
const OnGenreClick = styled.div`
  cursor: pointer;
`
import React from 'react';
import styled from 'styled-components';

interface Props {
  name: string,
  href: string,
  isBlank: boolean,
}
interface State {}

class Link extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <LinkStyle  href={this.props.href} target={this.props.isBlank? "blank": ""}>{this.props.name}</LinkStyle>
      </div>
    );
  }
}

export default Link;

const LinkStyle = styled.a`
  color: #93989A;
  font-size: 12px;
  font-family: 'Noto Sans';
  text-decoration: none;
  :hover{
    text-decoration: underline;
  }
`
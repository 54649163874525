import React, { useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query'
import { useParams } from "react-router";
import { useSelector, useDispatch } from 'react-redux';

import { AppStore, User, App, FilterType, Filter} from '../../../types'
import { showApps, createScreenShot } from '../../../logics/actions/'
import { storage } from '../../../utils/firebase'

import Loding from '../../atoms/Loading'
import Account from '../../organisms/Account'
import SideBar from '../../organisms/SideBar'
import ScreenShots from '../../organisms/ScreenShots'
import Admin from '../../organisms/Admin'
import FilterSelect from '../../organisms/FilterSelect'
import AppInfoLarge from '../../molecules/AppInfoLarge'
import MainWithSidebar from '../../templates/MainWithSideBar'
import { Helmet } from "react-helmet";

const AppPage: React.FC = () => {
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)
  const app: App | null = useSelector((state: AppStore) => state.appList.app)
  const filter: Filter = useSelector((state: AppStore) => state.filter)
  const dispatch = useDispatch()

  let { id } = useParams();

  useEffect(() => {
    if(id){showApps(id, dispatch);}
  }, [id, dispatch])

  const handleScreenShot = (event: any) => {
    event.preventDefault();
    const files = event.target.files;

    var metadata = {
      cacheControl: 'public,max-age=43200',
      contentType: 'image/jpeg'
    }

    if(app){
      for (var i = 0; i < files.length; i++) {
        const startMsec:number = Date.now();
        while (Date.now() - startMsec < 2);
        const NowTime: number = Date.now();
        console.log(files[i]);
        console.log(NowTime);
        storage.ref().child(app.id + "/" + app.versions[0].id + "/"+Math.random().toString(36).slice(-10)+".jpg")
        .put(files[i], metadata).then((snapshot: any) => {
            snapshot.ref.getDownloadURL().then(function(downloadURL: string) {
              createScreenShot(app, app.versions[0], downloadURL, app.genre, dispatch, NowTime)
            });
        });
      }
    }
  };


  return (
    <div>
      <MainWithSidebar sideBar={<SideBar />} >
        <Account />

        { app ? (
         <AppStyle>
          <Helmet>
            <title>{app.name} - yui.design</title>
          </Helmet>

          <AppInfoLargeStyle>
            <AppInfoLarge name={app.name} description={app.description} icon={app.versions[0].icon} company={app.company} genre={app.genre} url={app.url} />
          </AppInfoLargeStyle>

          <FilterSelect filters={[FilterType.PAGES,FilterType.PARTS]}/>


          <ScreenShotsStyle>
            <ScreenShots app={app} pages={filter.pages} parts={filter.parts} />
          </ScreenShotsStyle>

          </AppStyle>



        ): <Loding />}

        {currentUser.isAdmin && app ? <FileInput type='file' name="icon" accept='image/*' multiple onChange={(e)=>handleScreenShot(e)}/>: null}

        {currentUser.isAdmin && app ? (<Admin app={app} />): null }


      </MainWithSidebar>
    </div>
  );
}

export default AppPage;

const AppInfoLargeStyle = styled.div`
  padding: 72px 32px 0;
  ${media.lessThan("small")`
  padding: 32px 16px 0;
`}
`

const AppStyle = styled.div``


const ScreenShotsStyle = styled.div`
  padding: 0 32px;
  ${media.lessThan("small")`
    padding: 0px 5px 0px 16px;
    img{
      width: 160px;
    }
  `}
`

const FileInput = styled.input`
  width: calc(100% - 64px);
  height: 372px;
  background: rgba(196,196,196,0.1);
  border: 4px dotted #ddd;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  margin: 32px;
  :after{
    content: "ドラックアンドドロップしてスクリーンショットを追加できます";
    font-size: 18px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    height: 20px;
  }
  `
import * as actionTypes from '../../types/action.types';
import { ModalType, FilterType, SessionType, App } from '../../types/'

import { firebase, auth } from '../../utils/firebase'

export * from './screenShot'
export * from './app'
export * from './filter'

export const onShowModal = ( modalType : ModalType) => ({
  type: actionTypes.SHOW_MODAL,
  modalType: modalType,
});

export const onShowDetailModal = ( modalType : ModalType, detail: App, screenShot: string) => ({
  type: actionTypes.SHOW_DETAIL_MODAL,
  modalType: modalType,
  detail: detail,
  screenShot: screenShot
});


export const onHideModal = () => ({
  type: actionTypes.HIDE_MODAL
});


export const onShowSidebar = () => ({
  type: actionTypes.SHOW_SIDEBAR
})

export const onHideSidebar = () => ({
  type: actionTypes.HIDE_SIDEBAR

})



export const onShowFilter = ( filterType : FilterType, nowFilterType: FilterType | null) => {
  if(nowFilterType !== null){
    if (filterType === nowFilterType){
      return{
        type: actionTypes.HIDE_FILTER,
      }
    }else{
      return{
        type: actionTypes.SHOW_FILTER,
        filterType: filterType,
      }
    }

  }else{
    return{
      type: actionTypes.SHOW_FILTER,
      filterType: filterType,
    }
  }

};

export const onHideFilter = () => ({
  type: actionTypes.HIDE_FILTER
});


export const onSignInClick = (type: SessionType) => {
  if (type === SessionType.GOOGLE){
    const provider = new firebase.auth.GoogleAuthProvider()
    auth.signInWithRedirect(provider)
  }
  if (type === SessionType.TWITTER){
    const provider = new firebase.auth.TwitterAuthProvider()
    auth.signInWithRedirect(provider)
  }
}

export const onLogOutClick = () => {
  auth.signOut()
  window.location.replace("/")
}


export const setUser = (user: firebase.User | null) => ({
  type: actionTypes.SIGNIN_AUTH,
  currentUser: user,
})

export const setAdmin  = () => {
  return {
    type: actionTypes.ADMIN,
    isAdmin: true
  }
}


export const setFilter = (genre: string | null, pages: string | null, parts: string[]) => {
  return {
    type: actionTypes.SET_FILTER,
    genre: genre,
    pages: pages,
    parts: parts,
  }
}



export type Actions =
  | ReturnType<typeof onShowModal>
  | ReturnType<typeof onHideModal>
  | ReturnType<typeof onSignInClick>;
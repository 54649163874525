import React from 'react';

import PageLink from '../../atoms/PageLink'


const MenuPage: React.FC = () => {
  return (
    <div>
      <PageLink name="iOS" isActive={true} />
    </div>
    );
}

export default MenuPage;


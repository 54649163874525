import * as React from 'react';
import styled from 'styled-components';
import checkImg from '../../assets/check.svg'

interface State {}
interface Props {
  isActive: boolean,
}

class Check extends React.Component<Props, State> {

  render() {
    return (
      <CheckStyle>
        { this.props.isActive ?
          (<CheckIconActive><Image src={checkImg} alt="Check" /></CheckIconActive>):(<CheckIconInActive />)
        }
      </CheckStyle>
    );
  }
};

export default Check;

const CheckStyle = styled.div`
`
const CheckIconInActive = styled.div`
  background: #93989A;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid #93989A;
  box-sizing: border-box;
`

const CheckIconActive = styled.div`
  background: #FFFFFF;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  position: relative;
`

const Image = styled.img`
  position: absolute;
  left: 16.3%;
  right: 21.07%;
  top: 23.41%;
  bottom: 25.48%;
`
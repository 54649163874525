import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useDispatch } from 'react-redux'

import { onHideModal, onShowModal } from '../../../logics/actions'
import { ModalType, SessionType } from '../../../types'

import Text from '../../atoms/Text'
import Modal from '../../atoms/Modal'
import Social from '../../atoms/Social'
import { SocialType } from '../../atoms/Social'
import Icon, { IconType } from '../../atoms/Icon'
import { onSignInClick } from '../../../logics/actions'


const SignUpModal: React.FC = () => {

  const dispatch = useDispatch()

  return (
    <Modal>
      <SignUpModalStyle>
        <Event onClick={() => dispatch(onHideModal())}>
          <Icon type={IconType.Close} size={16}/>
        </Event>

        <TitleStyle>
          <Text color={"#282F2C"} isBold={true} fontSize={24} lineHeight={32}>アカウント登録</Text>
        </TitleStyle>

        <BodyStyle>
          <Description>
            <Text color={"#282F2C"} isBold={false} fontSize={14} lineHeight={21}>
              アカウント登録をすると、データを自由にフィルタリングすることができます！
            </Text>
            <TextPad><Text color={"#93989A"} isBold={false} fontSize={12} lineHeight={18}>
              アカウント登録すると<Link href="https://www.notion.so/ca518791d31d4021827f9fd7d1269aed">利用規約</Link>と<Link href="https://www.notion.so/0aafce7bf52947a383ee8c0a413a9427">プライバシーポリシー</Link>に同意したことになります。
            </Text></TextPad>
          </Description>

          <ButtonsList>
            <SocialButton onClick={()=>onSignInClick(SessionType.GOOGLE)}><Social type={SocialType.google} isSignUp={true} /></SocialButton>
            <SocialButton onClick={()=>onSignInClick(SessionType.TWITTER)}><Social type={SocialType.twitter} isSignUp={true} /></SocialButton>
            <SignIn onClick={() => dispatch(onShowModal(ModalType.SIGNIN))}>
              <Text color={"#8A959A"} isBold={false} fontSize={12} lineHeight={18}>登録済みの方はこちら</Text>
            </SignIn>
          </ButtonsList>
        </BodyStyle>
      </SignUpModalStyle>
    </Modal>
  );
}

export default SignUpModal;

const SignUpModalStyle = styled.div`
  width: 591px;
  padding: 32px;
  box-sizing: border-box;
  ${media.lessThan("small")`
    width: 296px;
    height: 446px;
    padding: 24px 32px 32px;
  `}
`;


const Description = styled.div`
  width: 242px;
  padding: 24px 0 32px;
`;

const Link = styled.a`
  color: #93989A;
  text-decoration: underline:
`;

const ButtonsList = styled.div`
  padding: 8px 16px 6px 36px;
  ${media.lessThan("small")`
    padding: 0;
  `}
`;

const SocialButton = styled.div`
  margin-top: 16px;
`;

const SignIn = styled.div`
  text-align: center;
  margin-top: 59px;
  cursor: pointer;
  text-decoration: underline;
  :hover{
    text-decoration: none;
  }
  ${media.lessThan("small")`
    margin-top: 56px;
  `}
`;

const TitleStyle = styled.div`
  width: 100%;
`;
const BodyStyle = styled.div`
  display: flex;
  ${media.lessThan("small")`
    display: block;
  `}
`

const Event = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;


const TextPad = styled.div`
  margin-top: 18px;
`;
import { combineReducers } from "redux";
import { sessionReducer } from "./auth";
import { modalReducer } from "./modal"
import { appsReducer } from "./app"
import { sideBarReducer } from "./sideBar"
import { sideFilterReducer } from "./sideFilter"
import { filterReducer } from "./filter"
import { screenShotsReducer } from "./screenShot"
import { existFilterReducer } from "./existFilter"

export const rootReducer = combineReducers({
  currentUser: sessionReducer,
  modal: modalReducer,
  sideBar: sideBarReducer,
  sideFilter: sideFilterReducer,
  filter: filterReducer,
  existFilter: existFilterReducer,
  appList: appsReducer,
  screenShotList: screenShotsReducer,
})
import React, { useMemo } from 'react';
import styled from 'styled-components';

import SideBar from '../../organisms/SideBar'
import TabList from '../../organisms/TabList'
import { useSelector, useDispatch } from 'react-redux';
import { AppStore, App, FilterType} from '../../../types'

import MainWithSidebar from '../../templates/MainWithSideBar'
import AppCard from '../../organisms/AppCard'
import Account from '../../organisms/Account'
import Empty from '../../molecules/Empty'
import Loding from '../../atoms/Loading'

import FilterSelect from '../../organisms/FilterSelect'

import { indexAdminApps, createApp } from '../../../logics/actions/'
import { AppList } from '../../../types/'

const Admin: React.FC = () => {
  const appsState: AppList = useSelector((state: AppStore) => state.appList)
  const dispatch = useDispatch()


  const ScreenShotItems = (apps: App[]) => {
    return(
      apps.map((td) => <AppCard key={td.id} app={td} />)
    )
  };

  useMemo(() => {
    indexAdminApps(dispatch);
  },[dispatch])

  return (
    <div>
      <MainWithSidebar sideBar={<SideBar />} >
        <Account />

        <TabList name="Admin" tab="アプリ"/>
        <FilterSelect filters={[FilterType.GENRE]}/>

        <Button onClick={()=>createApp(dispatch)}>アプリの追加</Button>

        { appsState.isLoading ?  <Loding /> :
          appsState.list.length === 0 ? <Empty /> : ScreenShotItems(appsState.list) }

      </MainWithSidebar>
    </div>
  );
}

export default Admin;



const Button = styled.button`
  border: 2px solid #ddd;
  margin: 32px 32px 14px 32px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #93989A;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  line-height: 19px;
  color: #93989A;
  font-family: 'Noto Sans';
  padding: 9px 12px;
`
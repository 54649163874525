import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text'
import { useHistory } from 'react-router-dom'


const Empty: React.FC = () => {
  const history = useHistory();

  const reset = () => {
    history.push({
      pathname: '',
      search: '',
    })
  }



  return (
  <Padding>
     <Text lineHeight={19} fontSize={14} color="#1B2B33" isBold={false}>当てはまるデザインがありません</Text>
     <Reset onClick={()=> reset()}>フィルターをリセット</Reset>
  </Padding>
  )
}

export default Empty


const Padding = styled.div`
  margin-top: 101px
  text-align: center;
  width: 100%;
`

const Reset = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #93989A;
  padding: 8px;
  cursor: pointer;
  font-family: 'Noto Sans';
  text-decoration: underline;
`
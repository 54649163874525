import React from 'react';
import styled from 'styled-components';

import Window from '../../atoms/Window';
import Text from '../../atoms/Text'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AppStore, User} from '../../../types'

import { onLogOutClick } from '../../../logics/actions'

const UserMenu: React.FC = () => {
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)

  return (
    <UserMenuStyle>
      <Window>
        <MenuLink onClick={()=>onLogOutClick()}>
          <Text color={"#282F2C"} isBold={false} fontSize={14} lineHeight={19} >ログアウト</Text>
        </MenuLink>
        { currentUser.isAdmin? (
          <MenuLink>
            <Link to="/admin">
              <Text color={"#282F2C"} isBold={false} fontSize={14} lineHeight={19} >アプリ管理</Text>
            </Link>
          </MenuLink>):null
        }
      </Window>
    </UserMenuStyle>
    );
}

export default UserMenu;

const UserMenuStyle = styled.div`
  width: 169px;
  position: absolute;
  right: 64px;
  top: 51px;
`

const MenuLink = styled.div`
  width: 169px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
`
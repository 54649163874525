import * as actionTypes from '../../types/action.types';
import { App, Version, ScreenShot , ModalType } from '../../types/'
import { Dispatch } from 'redux';

import { db } from '../../utils/firebase'
import { onShowDetailModal } from './index'

export const resetApp = () => {
  return {
    type: actionTypes.RESET_APP
  }
}

const fetchAppsSuccess = (app: App) => {
  return {
    type: actionTypes.INDEX_APPS,
    app: app
  }
}
const fetchAppSuccess = (app: App) => {
  return {
    type: actionTypes.READ_APP,
    app: app
  }
}
const fetchAppsFaild = () => {
  return {
    type: actionTypes.NO_APP,
  }
}


export const indexApps = async (dispatch: Dispatch, genre :string| null, last_app :App|null ) => {
  var updated_at :number|null = null

  if (last_app != null){
    updated_at = last_app.updated_at;
  }else{
    dispatch(resetApp());
  }

  var ref: firebase.firestore.Query

  if(genre === null){
    ref = db.collection('apps').where("published","==",true).orderBy("updated_at", 'desc').limit(4)
  }else{
    ref = db.collection('apps').where("published","==",true).where("genre","==",genre).orderBy("updated_at", 'desc').limit(4)
  }

  if(updated_at){
    ref = ref.startAfter(updated_at)
  }

  ref.get().then((snapShot) => {

    if (snapShot.docs.length === 0){
      dispatch(fetchAppsFaild());
    }

    snapShot.docs.forEach(doc => {
      const data = doc.data()
      const app: App = {
        id: doc.id,
        company: data.company,
        description: data.description,
        genre: data.genre,
        name: data.name,
        url: data.url,
        published: data.published,
        created_at: data.created_at,
        updated_at: data.updated_at,
        versions: [],
      }
      db.collection('apps').doc(doc.id).collection("versions").get().then((vs) => {
        vs.docs.forEach((doc) => {
          const data = doc.data();
          const ver: Version = {
            id: doc.id,
            name: data.name,
            icon: data.icon,
            is_latest: data.is_latest,
            published: data.published,
            screenShots: []
          }
          app.versions = [...app.versions, ver]
        });
        db.collection('apps').doc(doc.id).collection("versions").doc(app.versions[0].id).collection("ScreenShots").orderBy("updated_at").limit(10).get().then((ss) => {
          ss.docs.forEach((doc) => {
            const data = doc.data();
            const scs: ScreenShot = {
              id: doc.id,
              is_latest: data.is_latest,
              url: data.url,
              genre: data.genre,
              app_id: data.app_id,
              pages: data.pages,
              parts: data.parts,
              created_at: data.created_at,
              updated_at: data.updated_at,
            }
            app.versions[0].screenShots = [...app.versions[0].screenShots, scs]
          });
          dispatch(fetchAppsSuccess(app));
        });
      });
    });
  })
}


export const showApps = async (id: string, dispatch: Dispatch) => {
  dispatch(resetApp());
  db.collection('apps').doc(id).get().then((doc) => {
    const data = doc.data()
    if(!data){return}
    const app: App = {
      id: doc.id,
      company: data.company,
      description: data.description,
      genre: data.genre,
      name: data.name,
      published: data.published,
      url: data.url,
      versions: [],
      created_at: data.created_at,
      updated_at: data.updated_at,
    }
    db.collection('apps').doc(id).collection("versions").get().then((vs) => {
      vs.docs.forEach((doc) => {
        const data = doc.data();
        const ver: Version = {
          id: doc.id,
          name: data.name,
          icon: data.icon,
          is_latest: data.is_latest,
          published: data.published,
          screenShots: []
        }
        app.versions = [...app.versions, ver]
      });
      db.collection('apps').doc(doc.id).collection("versions").doc(app.versions[0].id).collection("ScreenShots").orderBy("updated_at").get().then((ss) => {
        ss.docs.forEach((doc) => {
          const data = doc.data();
          const scs: ScreenShot = {
            id: doc.id,
            is_latest: data.is_latest,
            url: data.url,
            genre: data.genre,
            app_id: data.app_id,
            pages: data.pages,
            parts: data.parts,
            created_at: data.created_at,
            updated_at: data.updated_at,
          }
          app.versions[0].screenShots = [...app.versions[0].screenShots, scs]
        });
        dispatch(fetchAppSuccess(app));
      });
    });
  });
}



export const onShowLoadDetailModal = ( modalType : ModalType, app_id: string, screenShot: string, dispatch: Dispatch) => {
  db.collection('apps').doc(app_id).get().then((doc) => {
    const data = doc.data()
    if(!data){return}
    const app: App = {
      id: doc.id,
      company: data.company,
      description: data.description,
      genre: data.genre,
      name: data.name,
      published: data.published,
      url: data.url,
      versions: [],
      created_at: data.created_at,
      updated_at: data.updated_at,
    }
    db.collection('apps').doc(app_id).collection("versions").get().then((vs) => {
      vs.docs.forEach((doc) => {
        const data = doc.data();
        const ver: Version = {
          id: doc.id,
          name: data.name,
          icon: data.icon,
          is_latest: data.is_latest,
          published: data.published,
          screenShots: []
        }
        app.versions = [...app.versions, ver]
      });
      db.collection('apps').doc(doc.id).collection("versions").doc(app.versions[0].id).collection("ScreenShots").orderBy("updated_at").get().then((ss) => {
        ss.docs.forEach((doc) => {
          const data = doc.data();
          const scs: ScreenShot = {
            id: doc.id,
            is_latest: data.is_latest,
            url: data.url,
            genre: data.genre,
            app_id: data.app_id,
            pages: data.pages,
            parts: data.parts,
            created_at: data.created_at,
            updated_at: data.updated_at,
          }
          app.versions[0].screenShots = [...app.versions[0].screenShots, scs]
        });
        dispatch(onShowDetailModal(modalType, app, screenShot))
      });
    });
  });
};





// -------------------------------------------------------------------
// Admin
// -------------------------------------------------------------------


export const updateApp = async (app: App, dispatch: Dispatch) => {
  db.collection('apps').doc(app.id).update({
    company: app.company,
    description: app.description,
    genre: app.genre,
    name: app.name,
    published: app.published,
    url: app.url
  })
  db.collection('apps').doc(app.id).collection("versions").doc(app.versions[0].id)
  .collection("ScreenShots").get().then((ss) => {
    ss.docs.forEach((doc) => {
      db.collection('apps').doc(app.id).collection("versions").doc(app.versions[0].id)
      .collection("ScreenShots").doc(doc.id).update({
        genre: app.genre,
        published: app.published
      })
    })
  });

  showApps(app.id, dispatch)
}

export const updateVersion = async (app:App, version: Version, dispatch: Dispatch) => {
  db.collection('apps').doc(app.id).collection('versions').doc(version.id).update({
    name: version.name,
    icon: version.icon,
    published: version.published,
  })
  showApps(app.id, dispatch)
}

export const indexAdminApps = async (dispatch: Dispatch) => {
  dispatch(resetApp());
  const ref = db.collection('apps').orderBy("updated_at").get()
  ref.then((snapShot) => {
    snapShot.docs.forEach(doc => {
      const data = doc.data()
      const app: App = {
        id: doc.id,
        company: data.company,
        description: data.description,
        genre: data.genre,
        name: data.name,
        url: data.url,
        published: data.published,
        versions: [],
        created_at: data.created_at,
        updated_at: data.updated_at,
      }
      db.collection('apps').doc(doc.id).collection("versions").get().then((vs) => {
        vs.docs.forEach((doc) => {
          const data = doc.data();
          const ver: Version = {
            id: doc.id,
            name: data.name,
            icon: data.icon,
            is_latest: data.is_latest,
            published: data.published,
            screenShots: []
          }
          app.versions = [...app.versions, ver]
        });
        db.collection('apps').doc(doc.id).collection("versions").doc(app.versions[0].id).collection("ScreenShots").orderBy("updated_at").get().then((ss) => {
          ss.docs.forEach((doc) => {
            const data = doc.data();
            const scs: ScreenShot = {
              id: doc.id,
              is_latest: data.is_latest,
              url: data.url,
              genre: data.genre,
              app_id: data.app_id,
              pages: data.pages,
              parts: data.parts,
              created_at: data.created_at,
              updated_at: data.updated_at,
            }
            app.versions[0].screenShots = [...app.versions[0].screenShots, scs]
          });
          dispatch(fetchAppsSuccess(app));
        });
      });
    });
  });
}

export const createApp = async (dispatch: Dispatch) => {

  const app_id: string = Math.random().toString(36).slice(-10)+Math.random().toString(36).slice(-10)
  const version_id: string = Math.random().toString(36).slice(-10)+Math.random().toString(36).slice(-10)

  db.collection('apps').doc(app_id).set({
    company: "",
    description: "",
    genre: "",
    name: "新しいアプリ",
    published: false,
    url: "",
    created_at: Date.now(),
    updated_at: Date.now(),
  })

  db.collection('apps').doc(app_id).collection('versions').doc(version_id).set({
    name: "1.0.0",
    icon: "https://placehold.jp/512x512.png",
    is_latest: true,
    published: false,
  })
  indexAdminApps(dispatch)
}

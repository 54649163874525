import * as actionTypes from '../../types/action.types';
import { SideFilter } from '../../types';

const initialState: SideFilter = {
  type: null,
  isActive: false,
};

export function sideFilterReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SHOW_FILTER: {
      return {
        ...state,
        type: action.filterType,
        isActive: true,
      }
    }
    case actionTypes.HIDE_FILTER: {
      return {
        ...state,
        type: null,
        isActive: false,
      }
    }
    default:
      return state;
  }
}
import React, { useEffect, useMemo } from 'react';
import styled from "styled-components"
import media from 'styled-media-query'

import SideBar from '../../organisms/SideBar'
import TabList from '../../organisms/TabList'
import { useSelector, useDispatch } from 'react-redux';
import { AppStore, User, FilterType, ScreenShot as SS, ModalType , Filter} from '../../../types'

import MainWithSidebar from '../../templates/MainWithSideBar'
import Jumbotron from '../../molecules/Jumbotron'
import Account from '../../organisms/Account'
import FilterSelect from '../../organisms/FilterSelect'
import Loding from '../../atoms/Loading'
import Empty from '../../molecules/Empty'

import { onHideFilter, indexScreenShots, onShowLoadDetailModal} from '../../../logics/actions'
import ScreenShot from '../../atoms/ScreenShot'
import InfiniteScroll from 'react-infinite-scroll-component'


const ScreenShots: React.FC = () => {
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)
  const screenShot: SS[] | null = useSelector((state: AppStore) => state.screenShotList.list)
  const filter: Filter = useSelector((state: AppStore) => state.filter)
  const isLoding: boolean = useSelector((state: AppStore) => state.screenShotList.isLoading)
  const isLast: boolean = useSelector((state: AppStore) => state.screenShotList.isLast)
  const dispatch = useDispatch()

  const render_jumbotron = (currentUser: User)=>{
    if (currentUser.isLoding){
      return null
    }
    if (currentUser.user != null){
      return null
    }else{
      return (
        <Jumbotron name={`yui.designは
        UIデザイナーのためのリファレンスサービスです。`}
        description="実務で参考になるデザインのみを集めました。日頃のUIデザインの参考にしてください。" />)
    }
  }

  const ScreenShotItems = screenShot.map((ss) =>
    <LeftPad onClick={()=> onShowLoadDetailModal(ModalType.DETAIL, ss.app_id , ss.id, dispatch)}><ScreenShot src={ss.url} width={170} key={ss.id} /></LeftPad>
  );

  useMemo(() => {
    indexScreenShots(filter.genre, filter.pages ,filter.parts, null, dispatch);
  }, [filter, dispatch])

  useEffect(() => {
    dispatch(onHideFilter());
  },[dispatch])

  const load = () =>{
    indexScreenShots(filter.genre, filter.pages ,filter.parts, screenShot[screenShot.length - 1] , dispatch);
  }

  return (
    <div>
      <MainWithSidebar sideBar={<SideBar />} >
        <Account />

        {render_jumbotron(currentUser)}

        <TabList name="iOS" tab="UIパターン"/>
        <FilterSelect filters={[FilterType.GENRE, FilterType.PAGES, FilterType.PARTS]}/>

        { isLoding? <Loding />:
        screenShot.length === 0 ? <Empty /> : (
          <InfiniteScroll dataLength={screenShot.length} next={load} hasMore={!isLast} loader={<Loding />}>
          <ScreenShotsStyle>
            {ScreenShotItems}
          </ScreenShotsStyle>
          </InfiniteScroll>
        )
        }

      </MainWithSidebar>
    </div>
  );
}

export default ScreenShots;


const ScreenShotsStyle = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  padding: 32px 40px 14px 40px;
  ${media.lessThan("small")`
    padding: 0px 5px 0px 16px;
    img{
      width: 164px;
    }
  `}
`

const LeftPad = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
  ${media.lessThan("small")`
    margin-right: 11px;
    margin-bottom: 12px;
  `}
`
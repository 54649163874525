import React from 'react'
import styled from 'styled-components'


import { useDispatch } from 'react-redux'
import { onHideModal, onShowModal } from '../../../logics/actions'
import { ModalType, SessionType } from '../../../types'

import Text from '../../atoms/Text'
import Modal from '../../atoms/Modal'
import Social from '../../atoms/Social'
import Icon, { IconType } from '../../atoms/Icon'
import { onSignInClick } from '../../../logics/actions'
import { SocialType } from '../../atoms/Social'


const SignInModal: React.FC = () => {

  const dispatch = useDispatch()

  return (
        <Modal>
          <SignInModalStyle>
            <Event onClick={()=> dispatch(onHideModal())}>
              <Icon type={IconType.Close} size={16}/>
            </Event>

            <TitleStyle>
              <Text color={"#282F2C"} isBold={true} fontSize={24} lineHeight={32}>ログイン</Text>
            </TitleStyle>

            <BodyStyle>
              <ButtonsList>
                <SocialButton onClick={()=>onSignInClick(SessionType.GOOGLE)}><Social type={SocialType.google} isSignUp={false} /></SocialButton>
                <SocialButton onClick={()=>onSignInClick(SessionType.TWITTER)}><Social type={SocialType.twitter} isSignUp={false} /></SocialButton>
                <SignUp onClick={()=> dispatch(onShowModal(ModalType.SIGNUP))}>
                  <Text color={"#93989A"} isBold={false} fontSize={12} lineHeight={18}>アカウントの登録はこちらから</Text>
                </SignUp>
              </ButtonsList>
            </BodyStyle>
          </SignInModalStyle>
        </Modal>
  );
}

export default SignInModal;

const SignInModalStyle = styled.div`
  padding: 32px;
`;


const ButtonsList = styled.div`
  padding: 32px 0 0;
`;

const SocialButton = styled.div`
  margin-top: 16px;
`;

const SignUp = styled.div`
  text-align: center;
  margin-top: 56px;
  cursor: pointer;
  text-decoration: underline;
  :hover{
    text-decoration: none;
  }
`;

const TitleStyle = styled.div`
  width: 100%;
`;

const BodyStyle = styled.div`
  display: flex;
`

const Event = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

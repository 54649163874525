import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Actions } from '../../../logics/actions'

export enum type{
  Primary,
  Secondary,
}
interface Props {
  name: string,
  type: type,
  action: Actions
}

const Button: React.FC<Props> = props => {
  const dispatch = useDispatch();
  return (
    <ButtonStyle type={props.type} onClick={()=> dispatch(props.action)}>
      {props.name}
    </ButtonStyle>
  );
};

export default Button;


const ButtonStyle = styled.div<{type: type}>`
  color: ${props => props.type === type.Primary ? '#FFFFFF' : '#1B2B33'};;
  font-family: 'Noto Sans';
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  font-size: 14px;
  background: ${props => props.type === type.Primary ? '#1B2B33' : '#FFFFFF'};
  border-radius: 36px;
  padding: 8px 20px;
  border:1px solid #282F2C;
  display: inline-block;
  cursor: pointer;
  :hover{
    opacity: 92%;
    transition: all 0.2s ease;
  }
`
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'


import Text from '../../atoms/Text'
import ToggleSelecter from '../../molecules/ToggleSelecter'
import Icon, { IconType } from '../../atoms/Icon'
import { useDispatch , useSelector} from 'react-redux';
import { onShowFilter, onShowModal } from '../../../logics/actions'
import { FilterType , Filter, AppStore, SideFilter, ModalType, User} from '../../../types'

interface Props {
  filters: FilterType[];
}
const FilterSelect: React.FC<Props> = ({filters}) => {
  const dispatch = useDispatch()
  const currentUser: User | null = useSelector((state: AppStore) => state.currentUser)
  const filter: Filter | null = useSelector((state: AppStore) => state.filter)
  const sideFilterState: SideFilter = useSelector((state: AppStore) => state.sideFilter)

  const partsText = (list: string[]) =>{
    var count = list.length
    if (count === 0){
      return null
    }

    if (count === 1){
      return list[0]
    }else{
      return list[0] + " +" + (count - 1)
    }
  }

  const requestSignup = (item: FilterType, sideFilterState: FilterType | null) =>{

    if (currentUser.user){
      return onShowFilter(item, sideFilterState)
    }else{
      return onShowModal(ModalType.SIGNUP)
    }
  }



  const ToggleSelecterItems =  filters.map((item) =>
    <ToggleSelecterStyle key={item} onClick={()=>dispatch(requestSignup(item, sideFilterState.type))}>
      { item === FilterType.GENRE ? <ToggleSelecter name={filter.genre} label={"ジャンル"} /> : null }
      { item === FilterType.PAGES ? <ToggleSelecter name={filter.pages} label={"ページ"} /> : null }
      { item === FilterType.PARTS ? <ToggleSelecter name={partsText(filter.parts)} label={"パーツ"} /> : null }
    </ToggleSelecterStyle>
  );

  return (
    <FilterStyle>
      <MarkStyle>
        <IconStyle>
          <Icon type={IconType.Filter} size={16}/>
        </IconStyle>
        <Text color="#93989A" fontSize={12} lineHeight={16} isBold={true}>フィルター</Text>
      </MarkStyle>

      <ToggleSelectersStyle>
        {ToggleSelecterItems}
      </ToggleSelectersStyle>

    </FilterStyle>
  );
}

export default FilterSelect;

const FilterStyle = styled.div`
  padding: 32px 40px 14px;
  ${media.lessThan("small")`
    padding: 27px 16px 14px 16px;
  `}
`
const MarkStyle = styled.div`
  display: flex;
`
const IconStyle = styled.div`
  margin-right: 4px;
`
const ToggleSelectersStyle = styled.div`
  margin-top: 4px;
  display: flex;
`
const ToggleSelecterStyle = styled.div`
  margin-right: 12px;
  cursor: pointer;
`
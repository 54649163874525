import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text'
import Check from '../../atoms/Check'

const CheckSelector: React.FC<{name: string, isActive: boolean}> = ({name, isActive}) => {
  return (
  <div>
      { isActive ? (
        <CheckSelectorStyle>
          <Check isActive={isActive} />
          <CheckText>
              <Text lineHeight={16} fontSize={14} color="#FFFFFF" isBold={isActive}>{name}</Text>
          </CheckText>
       </CheckSelectorStyle>
      ):(
        <CheckSelectorStyle>
          <Check isActive={isActive} />
          <CheckText>
              <Text lineHeight={16} fontSize={14} color="#93989A" isBold={isActive}>{name}</Text>
          </CheckText>
       </CheckSelectorStyle>
      )  }
  </div>
  )
}

export default CheckSelector

const CheckSelectorStyle = styled.div`
  display: flex;
`

const CheckText = styled.div`
  margin-left: 8px
`
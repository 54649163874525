import * as actionTypes from '../../types/action.types';
import { Modal } from '../../types/';

const initialState: Modal = {
  type: null,
  isActive: false,
  detail: null,
  screenShot: null,
  isLoading: true,
};

export function modalReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SHOW_MODAL: {
      return {
        ...state,
        type: action.modalType,
        isActive: true,
      }
    }
    case actionTypes.SHOW_DETAIL_MODAL: {
      return{
        ...state,
        type: action.modalType,
        detail: action.detail,
        screenShot: action.screenShot,
        isActive: true,
      }
    }

    case actionTypes.HIDE_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
}
import React from 'react';
import styled from 'styled-components';

import google from '../../assets/google.svg'
import twitter from '../../assets/twitter.svg'

export enum SocialType{
  google,
  twitter
}

interface Props {
  type: SocialType,
  isSignUp: boolean
}


const Social: React.FC<Props> = ({type, isSignUp}) => {
  return (
    <SocialStyle type={type}>
      {type === SocialType.google? <Icon src={google}/>: null}
      {type === SocialType.twitter? <Icon src={twitter}/>: null}

      <Text>
        {type === SocialType.google && isSignUp? "Googleで登録": null}
        {type === SocialType.twitter && isSignUp? "Twitterで登録": null}
        {type === SocialType.google && !isSignUp? "Googleでログイン": null}
        {type === SocialType.twitter && !isSignUp? "Twitterでログイン": null}
      </Text>

    </SocialStyle>
  );
}

export default Social;

const SocialStyle = styled.div<{type: SocialType}>`
  background: ${props => props.type === SocialType.google ? "#4285F4" : "#1DA1F2" };
  border-radius: 25.5px;
  width: 232px;
  height: 48px;
  cursor: pointer;
`

const Icon = styled.img`
  padding: 8px 12px;
  position: absolute;
`

const Text = styled.div`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  padding: 0 12px;
  :hover{
    opacity: 90%;
    transition: all 0.2s ease;
  }
`
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { xor } from 'lodash'

import Text from '../../atoms/Text'
import RadioSelector from '../../molecules/RadioSelector';
import CheckSelector from '../../molecules/CheckSelector';
import BackButton from '../../molecules/BackButton';
import { FilterType } from '../../../types/';
import { onHideFilter, setFilter } from '../../../logics/actions'
import { useSelector, useDispatch } from 'react-redux';
import { resetApp } from '../../../logics/actions/app';
import { resetScreenShot } from '../../../logics/actions/screenShot';


import { partsFilter, pagesFilter, Filter, ExistFilter, AppStore } from '../../../types'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

interface Props {
  type: FilterType | null
}

const SideFilter: React.FC<Props> = ({type}) => {

  const filter: Filter | null = useSelector((state: AppStore) => state.filter)
  const existFilter: ExistFilter = useSelector((state: AppStore) => state.existFilter)
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();

  const toggle = (array: string[], item: string) => xor(array, [item])

  const setParams = (label: string, params: string) =>{
    const values = queryString.parse(location.search, {arrayFormat: 'comma'});

    if(label === "genre"){
      values.genre = params
    }
    if(label === "pages"){
      values.pages = params
    }
    if(label === "parts"){
      values.parts = toggle(filter.parts, params)
    }

    history.push({
      pathname: '',
      search: queryString.stringify(values, {arrayFormat: 'comma'}),
    })
  }

  const resetParams = (label: FilterType | null) =>{
    const values = queryString.parse(location.search, {arrayFormat: 'comma'});

    if(label === FilterType.GENRE){
      values.genre = null
    }
    if(label === FilterType.PAGES){
      values.pages = null
    }
    if(label === FilterType.PARTS){
      values.parts = null
    }

    history.push({
      pathname: '',
      search: queryString.stringify(values, {arrayFormat: 'comma'}),
    })
  }


  useMemo(() => {
    const values = queryString.parse(location.search, {arrayFormat: 'comma'});
    var genre: string | null = null;
    var pages: string | null = null;
    var parts: string[] = [];

    if (values.genre !== null && values.genre !== undefined){
      genre = values.genre.toString()
    }
    if (values.pages !== null && values.pages !== undefined){
      pages = values.pages.toString()
    }
    if (values.parts !== null && values.parts !== undefined){
      if(typeof values.parts !== "string"){
        parts = values.parts
      }
      if(typeof values.parts === "string"){
        parts = [values.parts]
      }
    }
    dispatch(resetApp())
    dispatch(resetScreenShot())
    dispatch(setFilter(genre, pages, parts))


  }, [dispatch, location]);


  const GenreFilterItems = existFilter.genre.map((item) =>
      <FilterItem key={item} onClick={() => setParams("genre", item)} >
        <RadioSelector isActive={ filter.genre === item } name={item} />
      </FilterItem>
  );

  const PagesFilterItem = Object.keys(pagesFilter).map((item) =>
    <div>
      <SubHead>
        <Text lineHeight={20} fontSize={15} color="#93989A" isBold={true}>{item}</Text>
      </SubHead>
      {
        pagesFilter[item].map((mi) =>(
        existFilter.pages.includes(mi) ?
        <FilterItem key={mi} onClick={() => setParams("pages", mi)}>
          <RadioSelector isActive={filter.pages === mi} name={mi} />
        </FilterItem>
        : ""
        ))
      }
    </div>
  );

  const PartsFilterItem = Object.keys(partsFilter).map((item) =>
    <div>
      <SubHead>
        <Text lineHeight={20} fontSize={15} color="#93989A" isBold={true}>{item}</Text>
      </SubHead>
      {
        partsFilter[item].map((mi) =>(
        existFilter.parts.includes(mi) ?
        <FilterItem key={mi} onClick={() => setParams("parts", mi)}>
          <CheckSelector isActive={ filter.parts.indexOf(mi) !== -1} name={mi} />
        </FilterItem>
        : ""
        ))
      }

    </div>
  );

  return (
    <SideFilterStyle>
      <SideFilterContent>
        <Nav onClick={()=>dispatch(onHideFilter())}>
          <BackButton />
        </Nav>

        <Head>
          <Text lineHeight={22} fontSize={16} color="#FFFFFF" isBold={true}>
            { type === FilterType.GENRE ? "ジャンル" : null }
            { type === FilterType.PAGES ? "ページ" : null }
            { type === FilterType.PARTS ? "パーツ" : null }
          </Text>
          <Reset onClick={()=>resetParams(type)}>
            リセット
          </Reset>
        </Head>

        { type === FilterType.GENRE ? GenreFilterItems : null }
        { type === FilterType.PAGES ? PagesFilterItem : null }
        { type === FilterType.PARTS ? PartsFilterItem : null }

      </SideFilterContent>
    </SideFilterStyle>
  );
}

export default SideFilter;


const SideFilterStyle = styled.div`
  width: 188px;
  height: 100vh;
  z-index: 60;
  overflow-y: scroll;
  box-shadow: 0px 0px 37px rgba(0, 0, 0, 0.04);
  background: #26353C;
  z-index: 1;
`

const SideFilterContent = styled.div`
  padding: 24px 20px 24px 24px;
  z-index: 100;
`

const FilterItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
`

const Nav = styled.div`
  display: flex;
  margin-bottom: 24px;
  cursor: pointer;
`

const Head = styled.div`
  margin-bottom: 12px;
  display: flex;

`
const Reset = styled.div`
  margin-left: 30px;
  font-size: 12px;
  line-height: 12px;
  margin-top: 7px;
  text-decoration: underline;
  color: #93989A;
  cursor: pointer;
  font-family: 'Noto Sans'
`

const SubHead = styled.div`
  margin: 12px 0 4px;
`
import * as actionTypes from '../../types/action.types';
import { ExistFilter } from '../../types';

const initialState: ExistFilter = {
  genre: [],
  pages: [],
  parts: [],
};

export function existFilterReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SET_EXIST_FILTER: {
      return {
        ...state,
        genre: action.genre,
      }
    }
    case actionTypes.SET_EXIST_SS_FILTER: {
      return {
        ...state,
        pages: action.pages,
        parts: action.parts,
      }
    }
    default:
      return state;
  }
}
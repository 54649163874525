
export const genreFilter =
["エンターテイメント",
"ショッピング",
"スポーツ",
"SNS",
"ナビゲーション",
"ニュース",
"ビジネス",
"ファイナンス",
"フード/ドリンク",
"ブック",
"ヘルスケア",
"ミュージック",
"メディカル",
"ユーティリティ",
"ライフスタイル",
"教育",
"雑誌/新聞",
"仕事効率化",
"子ども向け",
"辞書/辞典",
"写真/ビデオ",
"天気",
"旅行"]


export const pagesFilter: { [index: string]: string[] } = {
  'Onboarding': ["Guided Tour", "Launch Screens","Signup", "Logins", "Setups", "Walkthroughs"],
  'Content': [
              "Articles & News",
              "Booking Details",
              "Bookmarks & Favs",
              "Browsers",
              "Calendars",
              "Content Details",
              "Date & Time",
              "Discover & Explore",
              "Event",
              "Empty States",
              "Games",
              "Groups & Rooms",
              "Home",
              "Libraries",
              "Maps",
              "Navigation",
              "Notes & Tasks",
              "Posts",
              "Product Details",
              "Quizzez",
              "Recipes & Menus",
              "Review Processes",
              "Settings",
              "Stories",
              "Suggestions",
              "Tag Clouds",
              "Timeline & History",
              "Venue Details"],
  'Communication':[
              "About",
              "Action Options",
              "Acknowledgement",
              "Alerts & Reminders",
              "Coach Marks",
              "Confirmation",
              "Error",
              "Feature Info",
              "Help & Feedback",
              "Instruction",
              "Loading",
              "Permission",
              "Privacy & Terms",
              "Pull to Refresh",
              "Verification"],
  'Data':[
              "Charts",
              "Create",
              "Drawing",
              "Edit",
              "Filter & Sort",
              "Other Actions",
              "Progress",
              "Search",
            ],
  'Social':[
            "Achievements",
            "Activity & Notification",
            "Bots",
            "Comments",
            "Contacts",
            "Charts & Messaging",
            "Flag & Report",
            "Follow",
            "Invite & Find Friends",
            "Leaderboard",
            "Like & Vote",
            "Profile & Account",
            "Reviews & Rating",
            "Share"
          ],

  'Media':[
          "AR",
          "Audio Playback",
          "Camera",
          "Filter & Effects",
          "Photos",
          "Recording",
          "Videos"],
  'Purchase':[
          "Ads",
          "Carts & Orders",
          "Checkout",
          "Payment Method",
          "Pricing",
          "Promos & Deals",
          "Remittance & Wallet",
          "Subscription"],
  'Layout':[
          "Dark Mode",
          "Full-screen Mode",
          "Grid Layout",
          "Horizontal Layout",
          "Skelton"]
}

export const partsFilter: { [index: string]: string[] } = {

  'Bars':[
    "Nav Bars",
    "Nav Bars (Large Title)",
    "Tab Bars",
    "Tabs",
    "Toolbars",
    "Search Bars"],
  'Container':[
    "Action Sheets",
    "Backdrops",
    "Banners",
    "Bottom Sheets",
    "Cards",
    "Chips",
    "Data Tables",
    "Image Lists",
    "Lists",
    "Navigation Drawer",
    "Side Sheets"],
  'Control':[
    "Buttons",
    "Checkbox & Radio",
    "FAB",
    "Page Controls",
    "Pickers",
    "Progress Indcators",
    "Text Fields",
    "Segmented Control",
    "Sliders",
    "Steppers",
    "Switches"],
  'Imagery':[
    "Hero Images",
    "Informational",
    "Thumbnails"],
  'Popover':[
    "Dialogs",
    "Menus",
    "Tooltips",
    "Snackbars"]
}
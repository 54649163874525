import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  src: string,
  width: number,
}


const ScreenShot: React.FC<Props> = ({src, width}) => {
  return (
    <ScreenShotWrap width={width} >
      <Image src={src.replace(
        "https://firebasestorage.googleapis.com/v0/b/yui-design.appspot.com/o/"
      ,"https://storage.googleapis.com/yui-design.appspot.com/")
      .replace(
        ".jpg"
      ,"_375x812.jpg")
      } width={width} />
    </ScreenShotWrap>
  );
}

export default ScreenShot;

const Image = styled.img<{width: number}>`
  width: ${props => props.width + "px"};
  position: absolute;
  top: 0;
  z-index: 2;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  ${media.lessThan("small")`
  width: 164px;
`};
}
`

const ScreenShotWrap = styled.div<{width: number}>`
  border-radius: 8px;
  width: ${props => props.width + "px"};
  position: relative;

  &:before{
    content: ' ';
    background: #f0f;
    padding-top: 216.53%;
    display: block;
    position: relative;
    background: rgba(196,196,196,0.1);
    border: 1px solid #EEEEEE;
    border-radius: 8px;
  }
  ${media.lessThan("small")`
    width: 164px;
  `};
}
`
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'react-router-dom'

import Text from '../../atoms/Text'
import Tab from '../../molecules/Tab'

interface Props {
  name: string,
  tab: string
}
interface State {}

class TabList extends React.Component<Props, State> {
  render(){
    return (
      <TabStyle>
        <HeadStyle>
          <Text color="#1B2B33" fontSize={48} lineHeight={65} isBold={true}>{ this.props.name }</Text>
        </HeadStyle>

        <Link to="/">
          <TabItemStyle>
            <Tab name="アプリ" isActive={this.props.tab === "アプリ"}/>
          </TabItemStyle>
        </Link>

        <Link to="/screenshots/">
          <TabItemStyle>
            <Tab name="UIパターン" isActive={this.props.tab === "UIパターン"}/>
          </TabItemStyle>
        </Link>

      </TabStyle>
    );
  }
}

export default TabList;

const TabStyle = styled.div`
  margin: 0 40px;
  ${media.lessThan("small")`
    margin: 0;
  `}
`
const HeadStyle = styled.div`
  margin: 21px 0 16px;
  ${media.lessThan("small")`
    margin: 32px 16px 16px;
  `}
`
const TabItemStyle = styled.div`
  margin-right: 24px;
  display: inline-block;
  ${media.lessThan("small")`
    margin: 0;
  `}
`


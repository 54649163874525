import * as actionTypes from '../../types/action.types';
import { Dispatch } from 'redux';
import { db } from '../../utils/firebase';


const fetchListFilterSuccess = (genre: string[]) => {
    return {
        type: actionTypes.SET_EXIST_FILTER,
        genre: genre
    }
  }

  const fetchListFilterSSSuccess = (pages: string[], parts: String[]) => {
    return {
        type: actionTypes.SET_EXIST_SS_FILTER,
        pages: pages,
        parts: parts,
    }
  }


export const indexListFilter = async (dispatch: Dispatch) => {
    var ref: firebase.firestore.Query
    var list_genre: string[] = []
    var list_parts: string[] = []
    var list_pages: string[] = []
    ref = db.collection('apps').where("published","==",true)

    ref.get().then((snapShot) => {
        snapShot.docs.forEach(doc => {
            const data = doc.data();
            list_genre.push(data.genre)
        });
        dispatch(fetchListFilterSuccess(Array.from(new Set(list_genre))))
    })

    ref = db.collectionGroup("ScreenShots").where("published", "==", true)
    ref.get().then((snapShot) => {
        snapShot.docs.forEach(doc => {
            const data = doc.data();
            list_pages.push(data.pages)
            data.parts.forEach( (e: string) =>
                list_parts.push(e)
            )
        });
        dispatch(fetchListFilterSSSuccess(
            Array.from(new Set(list_pages)),
            Array.from(new Set(list_parts))
            ))
    })

}
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useDispatch } from 'react-redux';

import Logo from '../Logo'
import Icon, {IconType} from '../Icon'
import { Link } from 'react-router-dom'

import { onShowSidebar } from '../../../logics/actions/'



const Head: React.FC = () => {
  const dispatch = useDispatch()


  return (
    <HeadStyle>
      <HamPad onClick={() => dispatch(onShowSidebar())}>
        <Icon type={IconType.Hamburger} size={24} />
      </HamPad>
      <LogoPad>
        <Link to="/">
          <Logo size={32} />
        </Link>
      </LogoPad>
    </HeadStyle>
  );
}

export default Head;

const HeadStyle = styled.div`
  display: none;
  ${media.lessThan("small")`
    display: flex;
    height: 64px;
    background: #26353C;
    width: 100vw;
    text-align: center;
    box-sizing: border-box;
  `}
`

const HamPad = styled.div`
  margin: 20px 16px;
  cursor: pointer;
`

const LogoPad = styled.div`
  margin: 20px 0px;
  text-align: center;
  margin-right: 58px;
  width: 100%;
  cursor: pointer;
`
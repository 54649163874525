import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { onShowModal } from '../../../logics/actions'
import { ModalType } from '../../../types'

import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import { type } from '../../atoms/Button/';
import Character from '../../atoms/Character'

interface Props {
  name: string,
  description: string
}

interface State {}

class Jumbotron extends React.Component<Props, State> {
  render(){
    return (
      <HomeTextStyle>
        <HeadStyle>
          <Text color="#1B2B33" fontSize={26} lineHeight={42} isBold={true}>
            {this.props.name}
          </Text>
        </HeadStyle>
        <TextStyle>
        <Text color="#1B2B33" fontSize={12} lineHeight={16} isBold={false}>
          {this.props.description}
        </Text>
        </TextStyle>
        <ButtonStyle>
          <Button name={"アカウント登録"} type={type.Primary} action={onShowModal(ModalType.SIGNUP)} />
        </ButtonStyle>

        <Character />
        
        <Hr/>
      </HomeTextStyle>
    );
  }
}

export default Jumbotron;

const HomeTextStyle = styled.div`
  padding: 0px 32px 0px 40px;
  position: relative;
  ${media.lessThan("small")`
    padding: 0px 16px;
  `}
`

const HeadStyle = styled.div`
  margin-top: 56px;
  ${media.lessThan("small")`
    margin-top: 16px;
  `}
`

const TextStyle = styled.div`
  margin: 16px 0 32px;
  ${media.lessThan("small")`
    margin: 16px 0 24px;
  `}
`

const ButtonStyle = styled.div`
  margin-bottom: 51px;
  ${media.lessThan("small")`
    margin-bottom: 127px;
  `}
`

const Hr = styled.hr`
  border: 1px solid #EEE;
  ${media.lessThan("small")`
    margin: 0 -16px;
  `}
`
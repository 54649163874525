import * as React from 'react';
import styled from 'styled-components';

interface Props {
  color: string,
  fontSize: number,
  isBold: boolean,
  lineHeight: number,
}

interface State {}

class Text extends React.Component<Props, State> {
  render() {
    return (
      <TextStyle color={this.props.color} fontSize={this.props.fontSize} isBold={this.props.isBold} lineHeight={this.props.lineHeight}>
        {this.props.children}
      </TextStyle>
    );
  }
};

export default Text;


const TextStyle = styled.div<{color: string, fontSize: number, isBold: boolean, lineHeight: number}>`
  font-family: 'Noto Sans';
  font-style: normal;
  white-space: pre-line;
  font-weight: ${props => props.isBold ? 'bold' : 'normal'};
  font-size: ${props => props.fontSize + "px"};
  line-height: ${props => props.lineHeight + "px"};
  color: ${props => props.color};
  text-decoration: none;
`
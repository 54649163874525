import React from 'react';
import styled from 'styled-components';
import Link from '../../atoms/Link'

const MenuLink: React.FC = () => {

  return (
    <div>
      <LinkStyle>
        <Link name="yui.designとは"  href="https://www.notion.so/yui-design-53a11b67c4e5424bb38dacbb4b12ab46" isBlank={true}/>
      </LinkStyle>
      <LinkStyle>
        <Link name="利用規約" href="https://www.notion.so/ca518791d31d4021827f9fd7d1269aed" isBlank={true}/>
      </LinkStyle>
      <LinkStyle>
        <Link name="プライバシー" href="https://www.notion.so/0aafce7bf52947a383ee8c0a413a9427" isBlank={true}/>
      </LinkStyle>
    </div>
  );
}

export default MenuLink;


const LinkStyle = styled.div`
  padding: 10px 16px;
`

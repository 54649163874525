import React from 'react';
import styled from 'styled-components';

interface Props {}
interface State {}

class Modal extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <ModalStyle>{this.props.children}</ModalStyle>
      </div>
    );
  }
}

export default Modal;

const ModalStyle = styled.div`
  min-width: 100px;
  min-height: 100px;
  background: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
`
import * as React from 'react';
import styled from 'styled-components';
import characterImg from '../../assets/character.png'
import media from 'styled-media-query';

interface State {}
interface Props {}

class Character extends React.Component<Props, State> {

  render() {
    return (
      <CharacterStyle>
        <Image src={characterImg} alt="character" />
      </CharacterStyle>
    );
  }
};

export default Character;

const CharacterStyle = styled.div`
    position: absolute;
    right: 55px;
    bottom: 0px;
    ${media.lessThan("small")`
        right: 8px;
    `}
`

const Image = styled.img`
    width: 193px;
`

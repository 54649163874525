import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
   apiKey: "AIzaSyAdMz4n-0_2hxWn4Z-BMSd6sPkFciXY-pc",
   authDomain: "yui-design.firebaseapp.com",
   databaseURL: "https://yui-design.firebaseio.com",
   projectId: "yui-design",
   storageBucket: "yui-design.appspot.com",
   messagingSenderId: "292390446424",
   appId: "1:292390446424:web:bf183fa773190ce17fb12d",
   measurementId: "G-9M27HG4GN8"
 };

firebase.initializeApp(firebaseConfig)


const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export { firebase, auth, db, storage}
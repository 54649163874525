import React from 'react';
import styled from 'styled-components';
import LogoSVG from '../../assets/logo_white.svg'

const Logo: React.FC<{size: number}> = ({size}) => {
  return (
    <div>
      <LogoStyle><Img src={LogoSVG} alt="yui.design" size={size} /></LogoStyle>
    </div>
  );
}

export default Logo;

const LogoStyle = styled.h1`
  color: #000000;
  font-family: Hiragino Sans;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
`
const Img = styled.img<{size: number}>`
  height: ${props => props.size + "px"}
`
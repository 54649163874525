import * as actionTypes from '../../types/action.types';

const initialState = {
  list: [],
  isLoading: true,
  isLast: false
};

export function screenShotsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.INDEX_SCREENSHOTS: {
      return {
        ...state,
        list: state.list.concat(action.screenShots),
        isLoading: false
      }
    }
    case actionTypes.RESET_SCREENSHOT: {
      return {
        ...state,
        list: [],
        isLoading: true
      }
    }
    case actionTypes.NO_SCREENSHOT: {
      return {
        ...state,
        isLast: true
      }
    }
    default:
      return state;
  }
}
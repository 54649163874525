import * as actionTypes from '../../types/action.types';

const initialState = {
  isShow: false,
};

export function sideBarReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SHOW_SIDEBAR: {
      return {
        ...state,
        isShow: true,
      }
    }
    case actionTypes.HIDE_SIDEBAR: {
      return {
        ...state,
        isShow: false,
      }
    }
    default:
      return state;
  }
}
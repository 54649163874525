import React from 'react';
import styled from 'styled-components'
import media from 'styled-media-query'

import AppInfo from '../../molecules/AppInfo'
import ScreenShot from '../../atoms/ScreenShot'
import { Link } from 'react-router-dom'

import { App } from '../../../types/'
import { onShowDetailModal } from '../../../logics/actions'
import { useDispatch } from 'react-redux';
import { ModalType } from '../../../types'

const AppCard: React.FC<{app: App}> = ({app}) => {

  const dispatch = useDispatch()

  const ScreenShotItems = app.versions[0].screenShots.map((image) =>
    <ScreenShotStyle onClick={()=>dispatch(onShowDetailModal(ModalType.DETAIL, app, image.id))} key={image.id}>
        <ScreenShot src={image.url} width={170}/>
    </ScreenShotStyle>
  );


  return (
    <AppCardStyle>
      <Link to={'/apps/'+app.id}>
        <AppInfo name={app.name} description={app.description} image={app.versions[0].icon}/>
      </Link>
      <ScreenShotsStyle className="overflow">
        {ScreenShotItems}
        <Pad>　</Pad>
      </ScreenShotsStyle>
    </AppCardStyle>
  );
}

export default AppCard;

const AppCardStyle = styled.div`
  padding: 10px 0 38px 40px;
  a{
    text-decoration: none;
  }
  ${media.lessThan("small")`
    padding: 10px 0 10px 16px;
  `}
`

const ScreenShotsStyle = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 16px;
  display: flex;
  cursor: pointer;
`
const ScreenShotStyle = styled.div`
  margin-right: 16px;
  position: relative;
`

const Pad =styled.div`
  width: 1px;
  margin: 0 170px;
`

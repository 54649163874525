import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useDispatch, useSelector } from 'react-redux';

import { ModalType, AppStore } from '../../../types'
import { onShowModal } from '../../../logics/actions'

import Avatar from '../../atoms/Avatar'

const Account: React.FC = () => {
  const dispatch = useDispatch()
  const user: firebase.User | null = useSelector((state: AppStore) => state.currentUser.user)
  return (
    user ? (
      <AccountStyle onClick={()=>dispatch(onShowModal(ModalType.MENU))}>
        {user.photoURL ? (
          <Avatar src={user.photoURL} />
        ):(
          <Avatar src={"https://placehold.jp/512x512.png"} />
        )}
      </AccountStyle>
    ) : (
    <SignIn onClick={() => dispatch(onShowModal(ModalType.SIGNIN))}>
      <LoginText>ログイン</LoginText>
    </SignIn>)
  );
}

export default Account;

const SignIn = styled.div`
  top: 24px;
  right: 32px;
  position: absolute;
  cursor: pointer;
  ${media.lessThan("small")`
    top: 24px;
    right: 16px;
  `}
  :hover{
    text-decoration: underline;
  }
`;

const AccountStyle = styled.div`
  top: 16px;
  right: 24px;
  position: absolute;
  cursor: pointer;
  ${media.lessThan("small")`
    top: 16px;
    right: 16px;
  `}
`;


const LoginText = styled.div`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #1B2B33;

  ${media.lessThan("small")`
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
  `}

`
import * as React from 'react';
import styled from 'styled-components';

import close from '../../assets/close.svg'
import close_white from '../../assets/close_white.svg'
import filter from '../../assets/filter.svg'
import hamburger from '../../assets/hamburger.svg'

export enum IconType{
  Close,
  CloseWhite,
  Filter,
  Hamburger,
}
interface Props {
  type: IconType,
  size: number
}
interface State {}

class Icon extends React.Component<Props, State> {

  icon_asset(){
    switch (this.props.type) {
      case IconType.Filter:{return filter}
      case IconType.Close:{return close}
      case IconType.CloseWhite:{return close_white}
      case IconType.Hamburger:{return hamburger}
    }
  }

  render() {
    return (
      <IconStyle src={this.icon_asset()} size={this.props.size} />
    );
  }
};

export default Icon;


const IconStyle = styled.img<{size: number}>`
  width: ${props => props.size + "px"};
`
import React from 'react';
import styled from 'styled-components';
import Text from '../../atoms/Text'
import AppIcon from '../../atoms/AppIcon'


interface Props {
  name: string,
  description: string
  image: string
}

interface State {}


const AppInfo: React.FC<Props> = ({name, description,image}) => {

  return (
    <AppInfoStyle>
      <AppIcon src={image} size={56}/>
      <InfoStyle>
        <Text color="#282F2C" fontSize={20} lineHeight={32} isBold={true}>{name}</Text>
        <Text color="#93989A" fontSize={12} lineHeight={16} isBold={false}>{description}</Text>
      </InfoStyle>
    </AppInfoStyle>
  );
}

export default AppInfo;


const AppInfoStyle = styled.div`
  display: flex;
`


const InfoStyle = styled.div`
  padding: 2px 0px 4px 16px;
`


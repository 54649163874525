import { App, Version, ScreenShot , ModalType } from '../../types'
import { firebase, db, storage } from '../../utils/firebase'
import { Dispatch } from 'redux';
import { showApps, onShowLoadDetailModal } from './app'
import * as actionTypes from '../../types/action.types';




export const resetScreenShot = () => {
  return {
    type: actionTypes.RESET_SCREENSHOT
  }
}

export const noScreenShot = () => {
  return {
    type: actionTypes.NO_SCREENSHOT
  }

}

export const indexScreenShots = async (genre: string | null,
                                      pages: string | null,
                                      parts: string[], last: ScreenShot| null, dispatch: Dispatch) => {
  var ref = await db.collectionGroup("ScreenShots").where("published", "==", true).orderBy("updated_at")

  if (genre){
    ref = ref.where("genre", "==", genre)
  }

  if (pages){
    ref = ref.where("pages", "==", pages)
  }

  if (parts.length > 0){
    ref = ref.where("parts", "array-contains-any", parts)
  }
  if (last == null){
    ref = ref.limit(50)
  }else{
    ref = ref.limit(50).startAfter(last.created_at)
  }
  
  ref.get().then((ss) => {

    if (ss.docs.length === 0){
      dispatch(noScreenShot());
    }

    var screenShots: ScreenShot[] = [];
    ss.docs.forEach((doc) => {
      const data = doc.data();
      const scs: ScreenShot = {
        id: doc.id,
        is_latest: data.is_latest,
        url: data.url,
        genre: data.genre,
        app_id: data.app_id,
        pages: data.pages,
        parts: data.parts,
        created_at: data.created_at,
        updated_at: data.updated_at,
      }
      screenShots = [...screenShots, scs]
    });;
    dispatch(fetchScreenShotsSuccess(screenShots))
  });
}

const fetchScreenShotsSuccess = (screenShots: ScreenShot[]) => {
  return {
    type: actionTypes.INDEX_SCREENSHOTS,
    screenShots: screenShots
  }
}

export const createScreenShot = (app: App, version: Version, url: string, genre: string, dispatch: Dispatch, NowTime: number)=> {

  const screenshots_id: string = Math.random().toString(36).slice(-10)+Math.random().toString(36).slice(-10)

  db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenshots_id).set({
    is_latest: true,
    url: url,
    genre: genre,
    app_id: app.id,
    pages: "",
    parts: [],
    published: true,
    created_at: NowTime,
    updated_at: NowTime,
  })
  showApps(app.id, dispatch)
}

export const addPagesScreenShot = (app: App, version: Version, screenShot: ScreenShot, page: string, dispatch: Dispatch)=> {
  db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenShot.id).update({
    pages: page
  })
  onShowLoadDetailModal(ModalType.DETAIL, app.id, screenShot.id, dispatch)
}

export const addPartsScreenShot = (app: App, version: Version, screenShot: ScreenShot, parts: string , dispatch: Dispatch)=> {
  db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenShot.id).update({
    parts: firebase.firestore.FieldValue.arrayUnion(parts)
  })
  onShowLoadDetailModal(ModalType.DETAIL, app.id, screenShot.id, dispatch)
}

export const removePagesScreenShot = (app: App, version: Version, screenShot: ScreenShot, page: string, dispatch: Dispatch)=> {
  db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenShot.id).update({
    pages: ""
  })
  onShowLoadDetailModal(ModalType.DETAIL, app.id, screenShot.id, dispatch)
}

export const removePartsScreenShot = (app: App, version: Version, screenShot: ScreenShot, parts: string , dispatch: Dispatch)=> {
  db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenShot.id).update({
    parts: firebase.firestore.FieldValue.arrayRemove(parts)
  })
  onShowLoadDetailModal(ModalType.DETAIL, app.id, screenShot.id, dispatch)
}

export const deleteScreenShot = (app: App, version: Version, screenShot: ScreenShot, dispatch: Dispatch)=> {

  const url = screenShot.url
  .replace("https://firebasestorage.googleapis.com/v0/b/yui-design.appspot.com/o/","")
  .replace("%2F","/")
  .replace("%2F","/")
  .split("?alt=");

  var desertRef = storage.ref().child(url[0]);

  // Delete the file
  desertRef.delete().then(function() {
    db.collection('apps').doc(app.id).collection('versions').doc(version.id)
  .collection("ScreenShots").doc(screenShot.id).delete();
    showApps(app.id, dispatch)
  }).catch(function(error: any) {
    console.log(error)
  });
}
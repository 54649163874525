

import React from 'react';
import styled from 'styled-components';
import { BounceLoader } from 'react-spinners';

const Loding: React.FC = () => {
  return (
    <LodingStyle>
      <LodingAni>
        <BounceLoader
        sizeUnit={"px"}
        size={64}
        color={'#93989A'}
        loading={true}
        />
      </LodingAni>
    </LodingStyle>
  );
}

export default Loding;

const LodingStyle = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  margin-top: 64px;

`
const LodingAni = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  height: 64px;

  `
import React from 'react';
import styled from "styled-components"
import { useSelector } from 'react-redux';
import SideFilter from '../../organisms/SideFilter'
import { AppStore, SideFilter as SF } from '../../../types'

const ShowFilter: React.FC = () => {
  const sideFilter: SF = useSelector((state: AppStore) => state.sideFilter)

  return(
    <SideFilterStyle isActive={sideFilter.isActive} >
      <SideFilter type={sideFilter.type} />
  </SideFilterStyle>
  )
}

export default ShowFilter;


const SideFilterStyle = styled.div<{isActive: boolean}>`
  position: fixed;
  left: ${props => props.isActive ?  "0": "-188px"};
  z-index: 80;
  transition: 0.3s ease-in-out;
`

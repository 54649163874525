import React from 'react';
import styled from 'styled-components'
import media from 'styled-media-query'

import Text from '../../atoms/Text'
import HoverText from '../../atoms/HoverText'

interface Props {
  name: string,
  isActive: boolean
}

interface State {}

class Tab extends React.Component<Props, State> {
  render(){
    return (
      <TabStyle>
        <TextStyle>

          {this.props.isActive?
          (
          <Text color={"#282F2C"} fontSize={18} lineHeight={25} isBold={true}>
            {this.props.name}
          </Text>
          ):(
            <HoverText color={"#93989A"} fontSize={18} lineHeight={25} isBold={false} hover={"#1B2B33"} isHoverUnderLine={false}>
            {this.props.name}
          </HoverText>
          )
          }



        </TextStyle>
        {this.props.isActive ? (<Bar/>) : <GrayBar />}
      </TabStyle>
    );
  }
}

export default Tab;

const TabStyle = styled.div`
  display inline-block;
  ${media.lessThan("small")`
    width: 50vw;
    text-align: center;
  `}
`

const TextStyle = styled.div`
  padding: 0 4px;
  text-align: center;
  display inline-block;
  ${media.lessThan("small")`
    width: 50vw;
    text-align: center;
    padding: 7px 0px;
  `}
`

const Bar = styled.div`
  height: 2px;
  border-radius: 1px;
  background: #000;
`
const GrayBar = styled.div`
  display: none;
  ${media.lessThan("small")`
    display: block;
    height: 2px;
    background: #F5F5F5;
  `}
`

import { App } from './app.types'

export enum ModalType{
  SIGNIN,
  SIGNUP,
  MENU,
  DETAIL,
}

export interface Modal{
  type: ModalType | null,
  isActive: boolean,
  detail: App | null,
  screenShot: String | null,
  isLoading: boolean
}
import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text'
import Radio from '../../atoms/Radio'

const RadioSelector: React.FC<{name: string, isActive: boolean}> = ({name, isActive}) => {
  return (
  <div>
      { isActive ? (
        <RadioSelectorStyle>
          <Radio isActive={isActive} />
          <RadioText>
              <Text lineHeight={16} fontSize={13} color="#FFFFFF" isBold={isActive}>{name}</Text>
          </RadioText>
       </RadioSelectorStyle>
      ):(
        <RadioSelectorStyle>
          <Radio isActive={isActive} />
          <RadioText>
              <Text lineHeight={16} fontSize={13} color="#93989A" isBold={isActive}>{name}</Text>
          </RadioText>
       </RadioSelectorStyle>
      )  }
  </div>
  )
}

export default RadioSelector

const RadioSelectorStyle = styled.div`
  display: flex;
`

const RadioText = styled.div`
  margin-left: 8px
`
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Logo from '../../atoms/Logo'


const MenuHead: React.FC = () => {
  return (
    <MenuHeadStyle>
      <Link to="/">
        <Logo size={30} />
      </Link>
    </MenuHeadStyle>
  );
}

export default MenuHead;


const MenuHeadStyle = styled.div`
  padding: 18px 14px;
`
import React from 'react';
import styled from 'styled-components';

interface Props {
  isActive: boolean,
}
interface State {}

class Toggle extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <ToggleStyle isActive={this.props.isActive}>{this.props.children}</ToggleStyle>
      </div>
    );
  }
}

export default Toggle;

const ToggleStyle = styled.div<{isActive: boolean}>`
  display: inline-block;
  background: ${props => props.isActive ? '#1B2B33': '#FFFFFF'};
  border: 1px solid ${props => props.isActive ? '#1B2B33':'#C9CBCC'};
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.isActive ? '#FFFFFF' : '#93989A'};
  font-family: 'Noto Sans'
`
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
interface Props {
  src: string,
}
interface State {}

class Avatar extends React.Component<Props, State> {
  render() {
    return (
      <AvatarStyle src={this.props.src} alt={"avatar"}/>
    );
  }
};

export default Avatar;


const AvatarStyle = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  ${media.lessThan("small")`
    width: 32px;
    height: 32px;
  `}
`
import React from 'react';
import styled from 'styled-components';

interface Props {}
interface State {}

class Window extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <WindowStyle>{this.props.children}</WindowStyle>
      </div>
    );
  }
}

export default Window;

const WindowStyle = styled.div`
  min-width: 50px;
  min-height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 1px 23px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
`
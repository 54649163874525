import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setUser, setAdmin, indexListFilter} from './logics/actions'

import Home from './components/pages/Home';
import Popup from './components/pages/Popup'
import ShowFilter from './components/pages/Filter'
import AppPage from './components/pages/App'
import ScreenShot from './components/pages/ScreenShots'
import Admin from './components/pages/Admin'

import GlobalStyles from './utils/reset';
import { auth } from './utils/firebase'

const App: React.FC = () => {

  const dispatch = useDispatch()
  useMemo(() => {
    indexListFilter(dispatch);
  },[dispatch]);

  useEffect(() =>{
    const unlisten = auth.onAuthStateChanged(
      currentUser => {
        dispatch(setUser(currentUser))
        if (currentUser) {
          currentUser.getIdTokenResult().then((idTokenResult) => {
            if (!!idTokenResult.claims.admin) {
              dispatch(setAdmin())
            }
          })
        };
      },
    );
    return () => {
        unlisten();
    }
 });

  return(
    <div>
      <GlobalStyles />
      <BrowserRouter>
        <Popup />
        <ShowFilter />
        <Switch>
          <Route path="/apps/:id"><AppPage /></Route>
          <Route path="/screenshots/"><ScreenShot /></Route>
          <Route path="/admin"><Admin /></Route>
          <Route path="/"><Home /></Route>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_DETAIL_MODAL = 'SHOW_DETAIL_MODAL'


export const SHOW_FILTER = 'SHOW_FILTER';
export const HIDE_FILTER = 'HIDE_FILTER';
export const SET_FILTER = 'SET_FILTER'

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

export const SIGNIN_AUTH = 'SIGNIN_AUTH';
export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const ADMIN = 'ADMIN';

export const INDEX_APPS = 'INDEX_APPS';
export const READ_APP = 'READ_APP';
export const CREATE_APP = 'CREATE_APPS';
export const DELETE_APP = 'DELETE_APP';
export const RESET_APP = 'RESET_APP';
export const NO_APP = 'NO_APP';

export const CREATE_VERSION = 'CREATE_VERSION';
export const DELETE_VERSION = 'DELETE_VERSION';

export const INDEX_SCREENSHOTS = 'INDEX_SCREENSHOTS';
export const READ_SCREENSHOT = 'READ_SCREENSHOT';
export const CREATE_SCREENSHOT = 'CREATE_SCREENSHOT';
export const DELETE_SCREENSHOT = 'DELETE_SCREENSHOT'
export const RESET_SCREENSHOT = 'RESET_SCREENSHOT';
export const NO_SCREENSHOT = 'NO_SCREENSHOT';

export const SET_EXIST_FILTER = "SET_EXIST_FILTER";
export const SET_EXIST_SS_FILTER = "SET_EXIST_SS_FILTER";
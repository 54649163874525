import React from 'react';
import styled, { keyframes } from "styled-components"
import media from 'styled-media-query'
import Div100vh from 'react-div-100vh'
import { useSelector, useDispatch } from 'react-redux';

import { AppStore, ModalType, Modal } from '../../../types'

import { onHideModal } from '../../../logics/actions'
import SignInModal from '../../organisms/SignInModal'
import SignUpModal from '../../organisms/SignUpModal'
import UserMenu from '../../molecules/UserMenu'
import Detail from '../../organisms/Detail'


const Popup: React.FC = () => {
  const modal:Modal = useSelector((state: AppStore) => state.modal)
  const dispatch = useDispatch()

  var showModal = (modal: Modal) => {

    if(modal.type === ModalType.SIGNIN){
      return(
        <ModalStyle>
          <Div100vh>
          <CloseTap onClick={() => dispatch(onHideModal())}/>
          <SignInCenter>
            <SignInModal/>
          </SignInCenter>
          </Div100vh>
        </ModalStyle>
      )
    }

    if(modal.type === ModalType.SIGNUP){
      return(
        <ModalStyle>
          <Div100vh>
          <CloseTap onClick={() => dispatch(onHideModal())}/>
          <SignUpCenter>
            <SignUpModal/>
          </SignUpCenter>
          </Div100vh>
        </ModalStyle>
      )
    }

    if(modal.type === ModalType.MENU){
      return(
        <ModalHide>
          <Div100vh>
          <CloseTap onClick={() => dispatch(onHideModal())}/>
          <UserMenu />
          </Div100vh>
        </ModalHide>
      )
    }

    if(modal.type === ModalType.DETAIL){
      return(
        <ModalStyle>
          <Div100vh>
          <CloseTap onClick={() => dispatch(onHideModal())}/>
          <DetailCenter>
            <Detail/>
          </DetailCenter>
          </Div100vh>
        </ModalStyle>
      )
    }

    return null;
  }

  return showModal(modal);
}

export default Popup;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


const ModalStyle = styled.div`
  width: 100vw;
  background-color: rgba( 0, 0, 0, .3);
  position: fixed;
  animation: ${fadeIn} 0.2s ease-in;
  z-index: 99;
`

const SignInCenter = styled.div`
  position: absolute;
  width: 296px;
  height: 330px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: ${fadeIn} 0.3s ease-in;
`

const SignUpCenter = styled.div`
  position: absolute;
  width: 591px;
  height: 325px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: ${fadeIn} 0.3s ease-in;
  ${media.lessThan("small")`
    width: 296px;
    height: 446px;
  `}
`

const DetailCenter = styled.div`
  position: absolute;
  width: 856px;
  height: 655px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: ${fadeIn} 0.3s ease-in;
  ${media.lessThan("small")`
    width: 100vw;
    height: 100%;
  `}
`

const CloseTap = styled.div`
  width: 100%;
  height: 100%;
  positionL: fixed;
  z-index: 1;
`


const ModalHide = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
`
import * as actionTypes from '../../types/action.types';
import  { AppList } from '../../types/'

const initialState: AppList = {
  list: [],
  app: null,
  isLoading: true,
  isLast: false
};

export function appsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.INDEX_APPS: {
      return {
        ...state,
        list: state.list.concat(action.app),
        last_app: action.app,
        isLoading: false,
      }
    }
    case actionTypes.READ_APP: {
      return {
        ...state,
        app: action.app,
      }
    }
    case actionTypes.RESET_APP: {
      return {
        ...state,
        list: [],
        app: null,
        last_app: null,
        isLoading: true,
        isLast: false,
      }
    }
    case actionTypes.NO_APP: {
      return {
        ...state,
        app: null,
        isLoading: false,
        isLast: true
      }
    }
    default:
      return state;
  }
}


export enum FilterType {
  GENRE,
  PAGES,
  PARTS,
}
export interface SideFilter{
  type: FilterType | null,
  isActive: boolean,
}

export interface Filter{
  genre: string | null,
  pages: string | null,
  parts: string[],
}
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query'
import { useDispatch } from 'react-redux'

import { App, ModalType, ScreenShot as SS } from '../../../types'
import { onShowDetailModal } from '../../../logics/actions'

import ScreenShot from '../../atoms/ScreenShot'
import Empty from '../../molecules/Empty'

interface Props {
  app: App,
  pages: string | null,
  parts: string[]
}


const ScreenShots: React.FC<Props> = ({app, pages, parts}) => {

  const dispatch = useDispatch()

  const ScreenShotItems = (item: SS[]) => item.map((image) => (
    <ScreenShotStyle key={image.id}  onClick={()=>dispatch(onShowDetailModal(ModalType.DETAIL, app, image.id))}>
      <ScreenShot src={image.url} width={170}/>
    </ScreenShotStyle>)
  );

  const fil = (item: SS[]) =>{
    var screenShotsFilter: SS[] = item;

    if(pages){
      screenShotsFilter = screenShotsFilter.filter(s => s.pages.indexOf(pages) !== -1)
    }

    if(parts.length > 0){
      for (let i = 0; i < parts.length; i++) {
        screenShotsFilter =  screenShotsFilter.filter(s => s.parts.indexOf(parts[i]) !== -1)
      }
    }
    return screenShotsFilter
  }

  return (
    <div>
      <ScreenShotsStyle>
        { fil(app.versions[0].screenShots).length === 0 ? <Empty /> :
        ScreenShotItems(fil(app.versions[0].screenShots))
        }
      </ScreenShotsStyle>
    </div>
  );
}

export default ScreenShots;


const ScreenShotsStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ScreenShotStyle = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
  ${media.lessThan("small")`
    margin-right: 11px;
    margin-bottom: 12px;
    img{
      width: 164px;
    }
  `}
`

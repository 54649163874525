import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

interface Props {
  name: string,
  isActive: boolean
}
interface State {}

class PageLink extends React.Component<Props, State> {
  render() {
    return (
      <RemLink>
        <Link to="/">
          <LinkStyle isActive={this.props.isActive}>{this.props.name}</LinkStyle>
        </Link>
      </RemLink>
    );
  }
}

export default PageLink;

const RemLink = styled.div`
  a{
    text-decoration: none;
  }
`;

const LinkStyle = styled.div<{isActive: boolean}>`
  color: ${props => props.isActive ? '#FFFFFF' : '#93989A'};
  font-size: 14px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: ${props => props.isActive ? 'bold' : 'nomal'};
  padding: 11px 22px;
  border-left: 5px solid ${props => props.isActive ? '#FFFFFF' : 'transparent'};
  cursor: pointer;
`


import React from 'react';
import styled from 'styled-components';
import Text from '../../atoms/Text'
import Toggle from '../../atoms/Toggle'

interface Props {
  name: string | null,
  label: string
}
interface State {}

class ToggleSelecter extends React.Component<Props, State> {
  render() {
    return (
      <Toggle isActive={this.props.name ? true : false}>

        {this.props.name ? (
          <ToggleSelecterStyle>
            <ToggleLabelStyle>
              <Text lineHeight={19} fontSize={11} color="#CBCBCB" isBold={true}>{this.props.label}:</Text>
            </ToggleLabelStyle>
            <ToggleNameStyle>
               <Text lineHeight={19} fontSize={14} color="#FFFFFF" isBold={true}>{this.props.name}</Text>
            </ToggleNameStyle>
          </ToggleSelecterStyle>) :
          (<ToggleSelecterStyle>
            <TogglePadStyle>{this.props.label}</TogglePadStyle>
          </ToggleSelecterStyle>)
        }
      </Toggle>
    );
  }
}

export default ToggleSelecter;

const ToggleSelecterStyle = styled.div`
  display: inline-flex;
  font-family: 'Noto Sans';
`

const ToggleLabelStyle = styled.div`
  padding: 5px 7px 5px 8px;
`

const ToggleNameStyle = styled.div`
  padding 5px 10px 5px 0px;
`
const TogglePadStyle = styled.div`
  padding 5px 16px;
`
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  color: string,
  fontSize: number,
  isBold: boolean,
  lineHeight: number,
  hover: string,
  isHoverUnderLine: boolean,
}

interface State {}

class HoverText extends React.Component<Props, State> {
  render() {
    return (
      <TextStyle color={this.props.color} fontSize={this.props.fontSize} isBold={this.props.isBold} lineHeight={this.props.lineHeight} hover={this.props.hover} isHoverUnderLine={this.props.isHoverUnderLine}>
        {this.props.children}
      </TextStyle>
    );
  }
};

export default HoverText;


const TextStyle = styled.div<{color: string, fontSize: number, isBold: boolean, lineHeight: number, hover:string, isHoverUnderLine: boolean}>`
  font-family: 'Noto Sans';
  font-style: normal;
  white-space: pre-line;
  font-weight: ${props => props.isBold ? 'bold' : 'normal'};
  font-size: ${props => props.fontSize + "px"};
  line-height: ${props => props.lineHeight + "px"};
  color: ${props => props.color};
  text-decoration: none;
  :hover{
    color: ${props => props.hover};
    text-decoration: ${props => props.isHoverUnderLine? "none":"undeline"};
    text-decoration-color:  ${props => props.color};
    transition: all 0.2s ease;
  }

`
import * as actionTypes from '../../types/action.types';

const initialState = {
  user: null,
  isLoding: true,
  isAdmin: false
};

export function sessionReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SIGNIN_AUTH: {
      return {
        ...state,
        user: action.currentUser,
        isLoding: false
      }
    }
    case actionTypes.ADMIN: {
      return {
        ...state,
        isAdmin: true
      }
    }

    default:
      return state;
  }
}